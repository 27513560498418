import React from 'react';
import { ConnectSettingsMessages } from '../../config/messages';
import { isValidDomain, isValidSlug } from '../../helper/url';
import PSL from 'psl';
export interface DomainCountInterface {
  [key: string]: number;
}

const DomainErrorMessage = () => {
  return (
    <p style={{ color: 'red' }}>
      Die Subdomain ist nicht valide. Erfahre im{' '}
      <a href="https://hilfe.meetovo.de/de/article/eigene-subdomain-domain-verknupfen-woykz1/#3-wie-erkenne-ich-eine-subdomain">
        Hilfeartikel
      </a>
      , was eine Subdomain ist. Beispiel: sub.domain.de
    </p>
  );
};

export const DomainValidatior = [
  {
    validator: (rule: any, value: any) => {
      try {
        if (!isValidDomain(value)) {
          return Promise.reject(ConnectSettingsMessages.domainNotValid);
        }
      } catch (err) {
        return Promise.reject(err);
      }
      return Promise.resolve();
    }
  }
];

export const DomainValidatiorV2 = (
  domainCount: DomainCountInterface,
  subdomainCount: number,
  maxDomains: number
) => {
  return [
    {
      validator: (rule: any, value: any) => {
        // @ts-ignore
        const domain = PSL.parse(value).domain;
        try {
          if (!isValidDomain(value)) {
            return Promise.reject(ConnectSettingsMessages.domainNotValid);
          } else if (Object.keys(domainCount).length >= maxDomains && !domainCount[domain]) {
            return Promise.reject(ConnectSettingsMessages.notEnaugthDomains);
          } else if (subdomainCount >= maxDomains * 5) {
            return Promise.reject(ConnectSettingsMessages.notEnoughSubDomains);
          }
        } catch (err) {
          return Promise.reject(err);
        }
        return Promise.resolve();
      },
      message: <DomainErrorMessage />
    },
    { max: 64, message: 'Es sind maximal 64 Zeichen erforderlich' }
  ];
};

export const SlugValidatior = [
  {
    validator: (rule: any, value: any) => {
      try {
        if (!isValidSlug(value)) {
          return Promise.reject(ConnectSettingsMessages.slugNotValid);
        }
      } catch (err) {
        return Promise.reject(err);
      }
      return Promise.resolve();
    }
  },
  { max: 64, message: 'Es sind maximal 64 Zeichen erforderlich' }
];

export const FunnelSelectionValidator = [
  { required: true, message: 'Es muss ein Funnel ausgewählt werden.' }
];

export const getDomainCount = (domains: { name: string }[]) => {
  const domainCountData = domains.reduce((acc, { name }) => {
    // @ts-ignore
    const { domain } = PSL.parse(name);
    const domainParts = domain.split('.');
    const mainDomain = domainParts.slice(-2).join('.');

    acc[mainDomain] = acc[mainDomain] || 0;
    acc[mainDomain]++;

    return acc;
  }, {} as DomainCountInterface);

  const domainCount = Object.values(domainCountData).length;
  const subdomainCount = Object.values(domainCountData).reduce((acc, val) => acc + val, 0);

  return {
    domainCount,
    subdomainCount,
    domainCountData
  };
};
