import React, { useEffect, useState } from 'react';
import {
  Badge,
  Descriptions,
  Select,
  Typography,
  Space,
  message,
  Button,
  Tabs,
  Avatar as AntAvatar,
  Popover,
  Table,
  Tooltip
} from 'antd';
import {
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  RocketOutlined,
  FunnelPlotOutlined
} from '@ant-design/icons';
import moment from 'moment';
import {
  BookingInterface,
  LeadQualifierInterface,
  BookingChoiceInterface,
  LeadQualifierChoiceInterface,
  TrackingInterface,
  ProgressCollectorInterface,
  ProgressCollectorChoiceInterface,
  BookingActionEventInterface
} from '../../container/Events';
import formatEventTitle from '../../helper/formatEventTitle';
import AWSKeyVoiceMessagePlayer from '../AWSKeyVoiceMessagePlayer';
import Notes from '../Notes';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { StatusResponseType, StatusType } from '../../utils/responseTypes';
import { EventMessages, EventsMessages } from '../../../config/messages';
import dateFormats from '../../../config/dateFormats';
import { getTimeString } from '../../helper/dates';
import download from 'downloadjs';
import DeclineApplicantModal from './DeclineApplicantModal';
import DeleteButtonWithConfirmation from '../../../SharedUI/components/DeleteButtonWithConfirmation';
import NotReachedModal from './NotReachedModal';
import Avatar from 'react-avatar';
import { AiFillClockCircle } from 'react-icons/ai';
import { IoEllipsisHorizontalCircle } from 'react-icons/io5';
import { useEventsContext } from '../../context/EventsContext';
import FilesTable from './FilesTable';
import CommunicationTable from './CommunicationTable';
import ConditionalTooltip from '../../../SharedUI/components/ConditionalTooltip';
import DefaultSmallLoader from '../../../SharedUI/components/DefaultSmallLoader';
import { getTextFromHtml } from '../../../Builder/helper/sharedFunctions';

const GET_AVAILABLE_STATUS = gql`
  query {
    getAllBookingStatus {
      status
      value
      id
      color
      sortOrder
    }
  }
`;

export const CHANGE_BOOKING_STATUS = gql`
  mutation($input: ChangeBookingStatus!) {
    changeBookingStatus(input: $input) {
      funnelId
      name
      email
    }
  }
`;

//Change cancel schedule message accordingly
const CANCEL_SCHEDULE_MESSAGE = gql`
  mutation($input: CancelScheduleMessage!) {
    cancelScheduleMessage(input: $input) {
      funnelId
      messageId
    }
  }
`;

export interface PropsInterface {
  leadQualifier: LeadQualifierInterface[];
  eventType: string;
  selectedEvent: { index: any; event: BookingInterface | TrackingInterface } | undefined;
  setSelectedEvent: Function;
  changeBookingStatusLocally: Function;
  handleDelete: (id: number, version: string, type: string, callBack?: Function) => void;
  deleteLoading: boolean;
  eventLoading?: boolean;
  handleUpdateBookingEvents?: (eventId: number, bookingEvents: BookingActionEventInterface) => void;
}

interface AdaptedBookingChoiceInterface extends BookingChoiceInterface {
  totalDurationInSeconds?: number;
}

function EventDetailContent({
  leadQualifier,
  eventType,
  selectedEvent,
  setSelectedEvent,
  changeBookingStatusLocally,
  handleDelete,
  deleteLoading,
  handleUpdateBookingEvents
}: PropsInterface) {
  const [declineApplicantModalVisible, setDeclineApplicantModalVisibility] = useState(false);
  const [notReachedModalVisible, setNotReachedModalVisible] = useState(false);
  const { availableStatus } = useEventsContext();
  const { loading, error, data: statusData } = useQuery<StatusResponseType>(GET_AVAILABLE_STATUS);
  const [bookingStatus, setBookingStatus] = useState<StatusType[]>();
  //@ts-ignore
  const [currentStatus, setCurrentStatus] = useState(selectedEvent?.event?.bookingStatus?.value);
  //@ts-ignore
  const [currentStatusColor, setCurrentStatusColor] = useState(
    availableStatus[
      //@ts-ignore
      availableStatus.map(val => val.status).indexOf(selectedEvent?.event?.bookingStatus?.status)
    ]?.color
  );

  const [
    changeBookingStatus,
    { data, loading: statusChangeLoading, error: statusChangeError }
  ] = useMutation(CHANGE_BOOKING_STATUS);

  useEffect(() => {
    if (statusData?.getAllBookingStatus) {
      const temp = statusData?.getAllBookingStatus?.sort((a, b) => {
        if (a.sortOrder > b.sortOrder) return 1;
        else if (a.sortOrder < b.sortOrder) return -1;
        return 0;
      });

      setBookingStatus(temp);
    }
  }, [statusData]);

  useEffect(() => {
    //@ts-ignore
    setCurrentStatus(selectedEvent?.event?.bookingStatus?.value);
    //@ts-ignore
    const colorIndex = availableStatus
      .map(val => val.status)
      //@ts-ignore
      .indexOf(selectedEvent?.event?.bookingStatus?.status);
    setCurrentStatusColor(availableStatus[colorIndex]?.color);
  }, [JSON.stringify(selectedEvent)]);

  if (!selectedEvent) {
    return null;
  }

  if (!selectedEvent) {
    return null;
  }

  const choiceCollector: any = {};
  const { event } = selectedEvent || {};
  let files: string[] = [];
  let rawEvent: BookingInterface | any = {};
  let rawTracking: TrackingInterface | any = {};
  if (eventType !== 'tracking') rawEvent = event as BookingInterface;
  if (eventType === 'tracking') rawTracking = event as TrackingInterface;

  const { choices: initChoices, content, title } =
    eventType === 'tracking'
      ? prepareTrackingContent(event as TrackingInterface)
      : prepareBookingContent(event as BookingInterface);

  const choices = initChoices ?? [];

  choices.forEach((ch: AdaptedBookingChoiceInterface) => {
    let leadQualifierObj: LeadQualifierInterface | undefined = leadQualifier.find(
      (lq: LeadQualifierInterface) => ch.leadQualifierId === lq.id
    );
    if (leadQualifierObj) {
      let choiceObj: LeadQualifierChoiceInterface | undefined = leadQualifierObj.choices.find(
        (c: LeadQualifierChoiceInterface) => ch.choiceId === c.id
      );
      if (choiceObj) {
        const question = `${leadQualifierObj.question}`;

        if (!choiceCollector[question]) choiceCollector[question] = { title: [], value: [] };

        if (ch.stringValue || ch.numberValue) {
          choiceCollector[question].value.push(
            `${ch.stringValue || ch.numberValue} ${choiceObj.unit || ''}`
          );
        } else {
          choiceCollector[question].title.push(`${choiceObj.title}`);
        }

        if (ch.voiceMessageAWSKey)
          choiceCollector[question].voiceMessageAWSKey = ch.voiceMessageAWSKey;

        if (ch.totalDurationInSeconds) choiceCollector[question].time = ch.totalDurationInSeconds;
      }
    }
  });

  const handleChangeStatus = (value: string, props: any): void => {
    const eventId = selectedEvent.event.id;
    changeBookingStatus({
      variables: {
        input: { id: eventId, value }
      }
    })
      .then(() => {
        changeBookingStatusLocally(
          eventId,
          {
            action: props.data.status,
            value,
            color: props.data.color
          },
          event
        );
        //@ts-ignore
        if (value === 'DECLINE' && selectedEvent?.event?.version !== 'V1') {
          setDeclineApplicantModalVisibility(true);
        }
        message.success(EventMessages.success);
      })
      .catch(e => {
        message.error(EventMessages.error);
      });
  };

  const handleDownloadFile = (url: string, name: string) => {
    const hideMessage = message.loading(EventsMessages.downloadFileInProgress, 0);

    fetch(url)
      .then(async r => {
        const file = await r.blob();
        download(file, name);
      })
      .catch(() => message.error(EventsMessages.downloadFileFailed))
      .finally(hideMessage);
  };

  return (
    <>
      <div className="d-flex align-items-center mb-2" style={{ columnGap: 8 }}>
        <Avatar name={rawEvent.name} size="50" textSizeRatio={2} round={true} />
        <div className="d-flex flex-column justify-content-start">
          <div className="d-flex align-items-center">
            <ConditionalTooltip
              disable={!(rawEvent?.firstName || rawEvent.lastName || rawEvent.name)}
              title={
                rawEvent.firstName || rawEvent.lastName
                  ? `${rawEvent.firstName || ''} ${rawEvent.lastName || ''}`
                  : rawEvent.name
              }
            >
              <div className="event-name">{rawEvent.name}</div>
            </ConditionalTooltip>
            <Popover
              placement="rightTop"
              title=""
              content={
                <DeleteButtonWithConfirmation
                  loading={deleteLoading}
                  onDelete={async () => {
                    handleDelete(
                      selectedEvent?.event?.id,
                      // @ts-ignore
                      selectedEvent?.event?.version,
                      eventType,
                      () => {
                        setSelectedEvent(undefined);
                      }
                    );
                  }}
                />
              }
              trigger="click"
            >
              <Button
                className="ant-btn__without-background"
                type="ghost"
                data-testid="booking-delete-button"
                disabled={deleteLoading}
              >
                {deleteLoading ? (
                  <DefaultSmallLoader makeItCenter loading={deleteLoading} />
                ) : (
                  <IoEllipsisHorizontalCircle />
                )}
              </Button>
            </Popover>
          </div>
          <div className="d-flex align-items-center" style={{ columnGap: 4 }}>
            <AiFillClockCircle color="rgba(0,0,0,0.35)" />
            <div style={{ fontSize: 12, color: 'rgba(0,0,0,0.35)' }}>
              {moment(rawEvent.createdAt).fromNow() || 'Keine Angabe'}
            </div>
          </div>
        </div>
      </div>

      <Tabs defaultActiveKey="0">
        <Tabs.TabPane tab="Kontakt" key="0">
          <Space direction="vertical" size="large" style={{ display: 'flex' }}>
            {eventType !== 'tracking' ? (
              <div>
                <Select
                  value={currentStatus}
                  style={{ width: '100%' }}
                  loading={statusChangeLoading || loading}
                  onChange={handleChangeStatus}
                  {...(process.env.NODE_ENV === 'test' && { open: true })}
                  data-testid="booking-status-selection"
                >
                  {bookingStatus &&
                    bookingStatus.map(single => {
                      const colorIndex = availableStatus
                        .map(val => val.status)
                        .indexOf(single.status);
                      return (
                        <Select.Option key={single.id} value={single.value} data={single}>
                          <div
                            className="d-flex align-items-center"
                            style={{ columnGap: 8 }}
                            data-testid={single.status}
                          >
                            <div
                              style={{
                                height: 12,
                                width: 12,
                                borderRadius: '50%',
                                backgroundColor: availableStatus[colorIndex]?.color
                              }}
                            ></div>
                            <span>{getTextFromHtml(single.status)}</span>
                          </div>
                        </Select.Option>
                      );
                    })}
                </Select>
              </div>
            ) : null}

            {eventType !== 'tracking' ? (
              <div>
                <Typography.Title level={4}>Kontakt</Typography.Title>
                <Descriptions size="small" layout="vertical" bordered>
                  <Descriptions.Item label="E-Mail">{rawEvent.email}</Descriptions.Item>
                  <Descriptions.Item label="Telefon">
                    {rawEvent.phone ? (
                      <a
                        href={`tel:${
                          rawEvent.phone.startsWith('+') ? rawEvent.phone : '+' + rawEvent.phone
                        }`}
                      >
                        {rawEvent.phone.startsWith('+') ? rawEvent.phone : '+' + rawEvent.phone}
                      </a>
                    ) : (
                      'Keine Angabe'
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Datum">
                    {' '}
                    {moment(rawEvent.createdAt).format(dateFormats.dateReadableMonth)}
                  </Descriptions.Item>
                  {rawEvent.generalPurpose && (
                    <Descriptions.Item label="Zusatzfeld">
                      {rawEvent.generalPurpose}
                    </Descriptions.Item>
                  )}
                </Descriptions>
              </div>
            ) : null}

            <NotReachedModal
              visible={notReachedModalVisible}
              setVisibility={setNotReachedModalVisible}
              eventId={selectedEvent?.event?.id}
              selectedEvent={selectedEvent}
              setSelectedEvent={setSelectedEvent}
              handleUpdateBookingEvents={handleUpdateBookingEvents}
            />

            <DeclineApplicantModal
              visible={declineApplicantModalVisible}
              setVisibility={setDeclineApplicantModalVisibility}
              eventId={selectedEvent?.event?.id}
            />

            {eventType !== 'tracking' && rawEvent?.utmParameter ? (
              <div>
                <Typography.Title level={4}>Tracking</Typography.Title>
                <Descriptions size="small" layout="vertical" bordered>
                  <Descriptions.Item label="UTM-Parameter" style={{ lineBreak: 'anywhere' }}>
                    {rawEvent.utmParameter || 'Keine Angabe'}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            ) : null}
          </Space>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Eingaben" key="1">
          <Space direction="vertical" size="large" style={{ display: 'flex' }}>
            {eventType === 'tracking' ? (
              <div>
                <Typography.Title level={4}>Übersicht</Typography.Title>
                <Descriptions size="small" layout="vertical" bordered>
                  <Descriptions.Item label="Start">{`${moment(rawTracking.createdAt).format(
                    dateFormats.dateTime
                  )}`}</Descriptions.Item>
                  <Descriptions.Item label="Gesamtzeit">
                    {getTimeString(rawTracking.totalDurationInSeconds)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Schritte im Funnel">
                    {rawTracking.progressCollectors.length}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            ) : null}

            <Space direction="vertical" size="large" style={{ display: 'flex' }}>
              {Object.keys(choiceCollector).length ? (
                <div>
                  <Typography.Title level={4}>Antworten</Typography.Title>
                  <Descriptions column={1} layout="vertical" bordered>
                    {Object.keys(choiceCollector).map((key, idx) => (
                      <Descriptions.Item
                        key={idx}
                        label={
                          choiceCollector[key].time
                            ? `[Zeit: ${choiceCollector[key].time}] ${key}`
                            : key
                        }
                      >
                        {choiceCollector[key].voiceMessageAWSKey ? (
                          <AWSKeyVoiceMessagePlayer
                            voiceMessageAWSKey={choiceCollector[key].voiceMessageAWSKey}
                          />
                        ) : choiceCollector[key].value.length ? (
                          `Wert: ${choiceCollector[key].value.join(', ')}`
                        ) : (
                          `Auswahl: ${choiceCollector[key].title.join(', ')}`
                        )}
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                </div>
              ) : (
                <div>
                  <Typography.Title level={4}>Antworten</Typography.Title>
                  <p>Es stehen keine weiteren Informationen zur Verfügung</p>
                </div>
              )}
            </Space>

            {eventType === 'tracking' ? (
              <div>
                <Typography.Title level={4}>Quelle</Typography.Title>
                <Descriptions size="small" layout="vertical" bordered>
                  <Descriptions.Item label="Link">{rawTracking.source}</Descriptions.Item>
                </Descriptions>
              </div>
            ) : null}

            {rawEvent.eventStart ? (
              <div>
                <Typography.Title level={4}>Termin</Typography.Title>
                <Descriptions size="small" layout="vertical" bordered>
                  <Descriptions.Item label="Start">
                    {moment(rawEvent.eventStart).format(dateFormats.dateTime)}
                  </Descriptions.Item>
                  <Descriptions.Item label="Ende">
                    {moment(rawEvent.eventEnd).format(dateFormats.dateTime)}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            ) : null}
          </Space>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Notizen" key="2">
          {eventType !== 'tracking' ? <Notes eventId={selectedEvent?.event.id} /> : null}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Uploads" key="3">
          {eventType !== 'tracking' && (
            <div>
              <Typography.Title level={4}>Dateien</Typography.Title>
              <FilesTable handleDownloadFile={handleDownloadFile} selectedEvent={selectedEvent} />
            </div>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Kommunikation" key="4">
          {eventType !== 'tracking' && (
            <div>
              <CommunicationTable selectedEvent={selectedEvent} />
            </div>
          )}
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}

export default EventDetailContent;

const prepareBookingContent = (booking: BookingInterface) => {
  const content = (
    <React.Fragment>
      <label>
        <FunnelPlotOutlined /> Funnel:{' '}
      </label>
      <p>{booking?.funnelName}</p>
      <label>
        <UserOutlined /> Name
      </label>
      <p>{booking.name}</p>
      <label>
        <MailOutlined /> E-Mail
      </label>
      <p>{booking.email}</p>
      <label>
        <PhoneOutlined /> Telefon
      </label>
      <p>
        {booking.phone
          ? `${booking.phone.startsWith('+') ? booking.phone : '+' + booking.phone}`
          : 'Keine Angabe'}
      </p>
    </React.Fragment>
  );

  const title = formatEventTitle(booking.eventStart, booking.eventEnd);

  return {
    content,
    choices: booking.choices,
    title: <Badge color={booking.eventStart ? 'green' : 'blue'} text={title} />
  };
};

const prepareTrackingContent = (tracking: TrackingInterface) => {
  const getTimeString = (seconds: number) => {
    let timeString: string;
    if (seconds < 60) {
      timeString = `${seconds}s`;
    } else {
      let minutes = Math.floor(seconds / 60);
      timeString = `${minutes}min ${seconds % 60}s`;
    }

    return timeString;
  };

  const content = (
    <React.Fragment>
      <label>
        <FunnelPlotOutlined /> Funnel:{' '}
      </label>
      <p>{tracking.funnelName}</p>
      <label>
        <CalendarOutlined /> Interaktionsstart
      </label>
      <p>{`${moment(tracking.createdAt).format('DD.MM.YYYY HH:mm:ss')} Uhr`}</p>
      <label>
        <ClockCircleOutlined /> Gesamtzeit
      </label>
      <p>{getTimeString(tracking.totalDurationInSeconds)}</p>
      <label>
        <RocketOutlined /> Anzahl der Schritte insgesamt
      </label>
      <p>{tracking.progressCollectors.length}</p>
    </React.Fragment>
  );

  let choices: AdaptedBookingChoiceInterface[] = [];

  tracking.progressCollectors
    .filter((pc: ProgressCollectorInterface) => pc.leadQualifierId)
    .forEach((pc: ProgressCollectorInterface) => {
      // @ts-ignore
      const transformedChoices: AdaptedBookingChoiceInterface[] = pc.choices.map(
        (c: ProgressCollectorChoiceInterface) => ({
          leadQualifierId: pc.leadQualifierId,
          totalDurationInSeconds: getTimeString(pc.durationInSeconds),
          ...c
        })
      );
      choices = [...choices, ...transformedChoices];
    });

  return { content, choices, title: <Badge status="warning" text="Unvollständige Anfrage" /> };
};
