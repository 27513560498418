export const editorClasses = {
    root: 'nml__editor__root',
    toolbar: {
      hr: 'nml__editor__toolbar__hr',
      root: 'nml__editor__toolbar__root',
      bold: 'nml__editor__toolbar__bold',
      code: 'nml__editor__toolbar__code',
      undo: 'nml__editor__toolbar__undo',
      redo: 'nml__editor__toolbar__redo',
      link: 'nml__editor__toolbar__link',
      clear: 'nml__editor__toolbar__clear',
      italic: 'nml__editor__toolbar__italic',
      strike: 'nml__editor__toolbar__strike',
      hardbreak: 'nml__editor__toolbar__hardbreak',
      unsetlink: 'nml__editor__toolbar__unsetlink',
      alignLeft: 'nml__editor__toolbar__align__left',
      blockquote: 'nml__editor__toolbar__blockquote',
      bulletList: 'nml__editor__toolbar__bullet__list',
      alignRight: 'nml__editor__toolbar__align__right',
      orderedList: 'nml__editor__toolbar__ordered__list',
      alignCenter: 'nml__editor__toolbar__align__center',
      alignJustify: 'nml__editor__toolbar__align__justify',
    },
    content: {
      hr: 'nml__editor__content__hr',
      root: 'nml__editor__content__root',
      link: 'nml__editor__content__link',
      image: 'nml__editor__content__image',
      heading: 'nml__editor__content__heading',
      listItem: 'nml__editor__content__listItem',
      blockquote: 'nml__editor__content__blockquote',
      langSelect: 'nml__editor__content__lang__select',
      placeholder: 'nml__editor__content__placeholder',
      bulletList: 'nml__editor__content__bullet__list',
      orderedList: 'nml__editor__content__ordered__list',
      underline: 'nml__editor__content__underlined',
    },
  };
  