export function isValidURL(str: string, allowUpperCase: boolean = false) {
  if (allowUpperCase)
    return str.match(
      /^(https?:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,9}(:[0-9]{1,5})?(\/.*)?((.*=.*)(&?))?$/
    );

  return str.match(
    /^(https?:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,9}(:[0-9]{1,5})?(\/.*)?((.*=.*)(&?))?$/
  );
}

export function isValidSecureURL(str: string, allowUpperCase: boolean = false) {
  if (allowUpperCase)
    return str.match(
      /^https:\/\/[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,9}(:[0-9]{1,5})?(\/.*)?((.*=.*)(&?))?$/
    );

  return str.match(
    /^https:\/\/[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,9}(:[0-9]{1,5})?(\/.*)?((.*=.*)(&?))?$/
  );
}

export function isValidPhoneNumber(str: string) {
  return str.match(/^\+[0-9]+$/);
}

export function isValidYoutubeOrVimeoURL(str: string) {
  return str.match(
    /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$|^(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*$|((https|http):\/\/.*\.mp4)/
  );
}

export const urlFormValidator = ({ getFieldValue }: { getFieldValue: Function }, checkSecure?: boolean, allowUpperCase: boolean = false) => ({
  validator(rule: any, value: string) {
    if (value) {
      if (!isValidURL(value, allowUpperCase) && !checkSecure) {
        return Promise.reject(
          'Es muss eine valide URL angegeben werden. z.B. https://deine-seite.de'
        );
      } else if (!isValidSecureURL(value, allowUpperCase) && checkSecure) {
        return Promise.reject(
          'Es muss eine valide sichere URL angegeben werden. z.B. https://deine-seite.de'
        );
      }
    }

    return Promise.resolve();
  }
});

export const urlAndPhoneFormValidator = (
  { getFieldValue }: { getFieldValue: Function },
  checkSecure?: boolean
) => ({
    validator(rule: any, value: string) {
    if (value) {
      if (value.startsWith('+')) {
        if (!isValidPhoneNumber(value)) {
          return Promise.reject('Dies ist keine gültige Telefonnummer.');
        }
      } else {
        if (!isValidURL(value) && !checkSecure) {
          return Promise.reject('Es muss eine valide URL angegeben werden. z.B. https://deine-seite.de');
        }
        if ( !isValidSecureURL(value)) {
          return Promise.reject('Es muss eine valide URL angegeben werden. z.B. https://deine-seite.de');
        }
      }
    }
  }
});


export const isValidDomain = (domain = '') => {
  if (domain?.includes('.meetovo.')) return false;

  var re = /^(?!:\/\/)([a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.){2,}[a-z]{2,24}$/i;

  return !!domain?.match(re);
};

export const isValidSlug = (slug: string) => {
  var re = /^[\/][a-z0-9]+(?:-[a-z0-9]+)*$/g;

  return re.test(slug);
};

export const getUrlParam = (paramToGet: string): string => {
  try {
    const vars: { [key: string]: string } = {};
    // @ts-ignore
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m: any, key: string, value: string) => {
      if (typeof decodeURI !== 'undefined') vars[key] = decodeURI(value);
      else vars[key] = value;
    }
    );

    return paramToGet && vars[paramToGet] ? vars[paramToGet] : '';
  } catch (error) {
    return '';
  }
};

export const youtubeOrVimeoUrlFormValidator = ({ getFieldValue }: { getFieldValue: Function }) => ({
  validator(rule: any, value: string) {
    if (value && !isValidYoutubeOrVimeoURL(value)) {
      return Promise.reject(
        'Bitte gib eine gültige YouTube, Vimeo, Wistia oder MP4-Url ein.'
      );
    }

    return Promise.resolve();
  }
});