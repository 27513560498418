import React from 'react';
import { useNode } from '@craftjs/core';
import { useState } from 'react';
import { commonAspectRatios } from '../../../GeneralComponents/helper/cropImage';
import { enhanceStockImage } from '../../helper/images';
import { SingleSettingRendererProps } from './SingleSettingRenderer';
import ImageUploadV3 from '../../../GeneralComponents/ImageUploadV3';

interface ImageUploaderProps extends SingleSettingRendererProps {
  minWidth?: number;
  minHeight?: number;
}

export const ImageUploader = ({ element, onChange, values, ...restProps }: ImageUploaderProps) => {
  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    props: node.data.props
  }));

  const setImageSrc = (src: string, shape: string) => {
    setProp((props: any) => {
      props.src = src;
      props.form = shape;
    });
  };
  const [pexelsImage, setPexelsImage] = useState();
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const src = enhanceStockImage(props.src);
  return (
    <ImageUploadV3
      previewImage={src}
      onChange={(url: string, shape: any) => {
        setImageSrc(url, shape);
        setPexelsImage(undefined);
      }}
      {...element.otherProps}
      minWidth={element.otherProps?.minWidth || 1365}
      minHeight={element.otherProps?.minHeight || 100}
      existingImageURL={pexelsImage}
      onModalCancel={() => {
        setPexelsImage(undefined);
      }}
      freeSelectionEnabled={props.settings?.freeSelectionEnabled}
      possibleAspectRatios={
        props?.settings?.customAspectRatio ? [props.settings.customAspectRatio] : commonAspectRatios
      }
      setImageUploadLoadingForParent={setImageUploadLoading}
    />
  );
};
