import { TEMPLATE_KEYS } from '../components/elements/Container/helper';

export const buttonPresetsInitialValues = {
  boldText: false,
  borderRadius: '30px',
  size: 50,
  isDefaultGradient: false,
  gradientSettings: 'defaultGradient'
};

export const imagePresetsInitialValues = {
  borderRadius: '0px'
};

export const imageCarousalPresetsInitialValues = {
  borderRadius: '0px'
};

export const choicePresetsInitialValues = {
  templateKey: TEMPLATE_KEYS.ICON_ON_RIGHT,
  borderRadius: '10px',
  isDefaultGradient: false,
  gradientSettings: 'defaultGradient'
};
