import { createAsyncThunk } from '@reduxjs/toolkit';
import subscribeAddOnAPI from '../graphql/subscribeAddOn';
import cancelAddOnAPI from '../graphql/cancelAddOn';
import getAllAddOnsAPI from '../graphql/getAllAddOns';
import { ADD_ON_KEY, AddOnPayload } from '../config/types';

export const getAllAddOnsThunk = createAsyncThunk('get-all-add-ons', async () => {
  try {
    const response = await getAllAddOnsAPI();

    return { addOns: response.data.getAllAddOns };
  } catch (error) {
    console.error(error);
    throw error;
  }
});

export const subscribeAddOnThunk = createAsyncThunk(
  'subscribe-add-on',
  async (input: { addOnKey: ADD_ON_KEY}) => {
    try {
      const { addOnKey } = input;
      const response = await subscribeAddOnAPI(addOnKey);
      return { addOnId: response.data.subscribeAddOn.addOnId, permission: response.data.subscribeAddOn };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const cancelAddOnThunk = createAsyncThunk('cancel-add-on', async (key: ADD_ON_KEY) => {
  try {
    const response = await cancelAddOnAPI(key);
    return { addOnId: response.data.cancelAddOn.addOnId, permission: response.data.cancelAddOn };

  } catch (error) {
    console.error(error);
    throw error;
  }
});
