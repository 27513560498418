import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Form, Input, Upload } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import { ImageType, TagType } from '../utils/LibraryModels';
import SelectableItem from './SelectableItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import DefaultSmallLoader from '../../SharedUI/components/DefaultSmallLoader';
import { useAppDispatch } from '../../redux/hooks';
import { getAllImagesTagsThunk, getImagesWithPaginationThunk } from '../redux/libraryThunk';
import {
  setImageSearchValue,
  useAllLibraryImages,
  useAllLibraryImagesLoading,
  useAllTags,
  useImageSearchValue
} from '../redux/librarySlice';
import { debounce } from 'debounce';
import { getObjectId } from '../../helper/id';

const { Search } = Input;

function LibraryImagePicker({
  onSelectImage,
  handleChange,
  fileList
}: {
  onSelectImage?: (image: ImageType) => void;
  handleChange: ({ fileList }: any) => Promise<false | undefined>;
  fileList?: any[];
}) {
  const searchQuery = useImageSearchValue();
  const [form] = Form.useForm();
  const [uid] = useState(getObjectId());
  const dispatch = useAppDispatch();
  const [dragging, setDragging] = useState(false);

  const { images, total, page } = useAllLibraryImages();
  const allLibraryImagesLoading = useAllLibraryImagesLoading();

  const availableTags = useAllTags();

  const handleSearch = (e: any) => {
    dispatch(getImagesWithPaginationThunk({ searchQuery: e }));
  };

  const fetchMore = () => {
    dispatch(
      getImagesWithPaginationThunk({
        page: page + 1,
        limit: 30,
        searchQuery: form.getFieldValue('searchValue')
      })
    );
  };

  useEffect(() => {
    fetchMore();
    dispatch(getAllImagesTagsThunk());
  }, []);

  const debouncedSearch = useRef(
    debounce((value: string) => {
      handleSearch(value);
    }, 1000)
  ).current;
  const dragCounter = useRef(0);
  useEffect(() => {
    return () => {
      debouncedSearch.clear();
    };
  }, []);

  const handleSearchQuery = (value: string) => {
    debouncedSearch(value);
  };

  useEffect(() => {
    form.setFieldsValue({ searchValue: searchQuery });
  }, [searchQuery]);

  const handleFileChange = (files: { fileList: any; file: any }) => {
    setDragging(false);
    const filesData = {
      file: files.file,
      fileList: [files.fileList[files.fileList.length - 1]]
    };
    handleChange(filesData);
  };

  const handleDragEnter = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (dragCounter.current <= 1) {
      setDragging(true);
    }
    dragCounter.current += 1;
  };

  const handleDragLeave = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    dragCounter.current -= 1;
    if (dragCounter.current === 0) {
      setDragging(false);
    }
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    dragCounter.current = 0;
    setDragging(false);
  };

  return (
    <>
      <Form
        form={form}
        name="basic"
        onFinish={d => handleSearch(d?.searchValue)}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onDragOver={e => e.preventDefault()}
      >
        <Row>
          <Col span={24}>
            <Form.Item name="searchValue" className="mb-0">
              <Search
                allowClear
                onSearch={value => handleSearch(value)}
                onChange={e => {
                  const value = e.target.value;
                  dispatch(setImageSearchValue(value));
                  handleSearchQuery(value);
                }}
                placeholder="Archiv durchsuchen..."
              />
            </Form.Item>
          </Col>
        </Row>

        {!!availableTags.length && (
          <Row className="tags-section">
            <div className="builder__settings__image-select-modal__template-tags">
              {availableTags.map((tag: TagType) => (
                <CheckableTag
                  key={tag.id}
                  checked={searchQuery === tag.name}
                  onChange={() => {
                    dispatch(setImageSearchValue(tag.name));
                    handleSearch(tag.name);
                  }}
                >
                  {tag.name}
                </CheckableTag>
              ))}
            </div>
          </Row>
        )}

        {allLibraryImagesLoading && searchQuery && (
          <div className="d-flex justify-content-center m-2">
            <DefaultSmallLoader loading />
          </div>
        )}
        <InfiniteScroll
          next={fetchMore}
          hasMore={images?.length < total}
          loader={
            <div className="d-flex justify-content-center">
              <DefaultSmallLoader loading={allLibraryImagesLoading} />
            </div>
          }
          dataLength={images?.length}
          height={'50vh'}
        >
          <div
            className={
              !dragging ? 'image-upload__wrapper' + (availableTags.length === 0 ? ' mt-2' : '') : ''
            }
          >
            <div
              className={`image-upload__upload-field ${
                !dragging ? 'image-upload__upload-button mt-4' : 'image-upload__drag-area'
              }`}
            >
              <Upload.Dragger
                accept="gif,.jpg,.png,.jpeg"
                listType="picture-card"
                fileList={fileList}
                onChange={handleFileChange}
                className={'image-upload__upload-field-' + uid}
                showUploadList={false}
              >
                <p className="ant-upload-drag-icon mt-4 mb-2">
                  <CloudUploadOutlined className="icon-size" />
                </p>
                <p className="mt-2 mb-2">Vom Computer hochladen oder Datei reinziehen</p>
              </Upload.Dragger>
            </div>

            {images?.map((image: ImageType) => (
              <div key={image?.id} className="image-upload__list-item">
                <SelectableItem
                  tags={image.tags}
                  previewImageURL={image.imageLink}
                  deletable={true}
                  currentImage={image}
                  searchQuery={searchQuery}
                  onSelectImage={onSelectImage}
                />
              </div>
            ))}
          </div>
        </InfiniteScroll>
      </Form>
    </>
  );
}

export default LibraryImagePicker;
