import React, { useEffect, useState } from 'react';
import { useNode, useEditor, Element, Nodes } from '@craftjs/core';
import { FaArrowDown, FaArrowUp, FaArrowsAltV, FaListUl } from 'react-icons/fa';
import { Card, Col, message, Row, Slider, Tabs, Button, Switch } from 'antd';
import BuilderSettingsTitle from '../../../sharedUI/BuilderSettingsTitle';
import BuilderColorPickerButton from '../../../sharedUI/BuilderColorPickerButton';
import {
  copyNodeWithUpdatedProps,
  getClonedTree,
  getMainContainerNode,
  getRootAndMainContainerNodes,
  getUniqueId,
  isProvidedElementExistInFunnel,
  checkIfProvidedElementsExistsInBuilder,
  getKeyByValue,
  getValueByKey,
  getElementColor,
  getParentNodeId
} from '../../../../helper/craftJs';
import { CRAFT_ELEMENTS } from '../../../../config/craftElements';
import Column from '../../grid/Column';
import { Col as RBCol } from 'react-bootstrap';
import {
  ContainerSettingsTab,
  distanceAboveMarks,
  distanceBelowMarks,
  minHeightDesktopMarks,
  minHeightMobileMarks
} from '../config';
import {
  getColumnProps,
  getPaddingClassBySliderValue,
  getSliderValueByPaddingClass
} from '../helper';
import { PlayCircleOutlined, SaveOutlined } from '@ant-design/icons';
import {
  ContainerMessages,
  UserInteractingComponentsMessages
} from '../../../../../config/messages';
const { TabPane } = Tabs;
import TemplatesPanel from '../../../TemplatesPanel/TemplatePanel';
import {
  canAddTemplateInsideCurrentPage,
  userInteractingComponents,
  elementsOnceInFunnel
} from '../../../../helper/elementTemplate';
import WarningModal from '../../../../../SharedUI/components/WarningModal';
import ColumnGrid from '../../grid/ColumnGrid';
import { SelectedTemplate } from '../../../TemplatesPanel/interfaces';
import ContainerSettingsSectionId from './ContainerSettingsSectionId';
import { useIsMobileView } from '../../../../hooks/useIsMobileView';
import { useCurrentlyEditingBuilderTheme } from '../../../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';
import ImageUploadV3 from '../../../../../GeneralComponents/ImageUploadV3';
import {
  ImageAvailableOpacity,
  defaultImageOpacity
} from '../../../../AdBuilder/interfaces/ImageSizeInterface';
import BuilderSettingsTogglePro from '../../../sharedUI/BuilderSettingsTogglePro';
import {
  containerMinHeightForDesktop,
  containerMinHeightForMobile,
  defaultContainerMinHeightForDesktop,
  defaultContainerMinHeightForMobile
} from '../../../../AdBuilder/interfaces/containerHeightInterface';
import ColumnAlignmentButtons from '../../../../../SharedUI/components/ColumnAlignmentButtons';
import { useAppDispatch } from '../../../../../redux/hooks';
import { setMobileView } from '../../../../redux/builderSlice';
import { containerBackgroundAspectRatios } from '../../../../../GeneralComponents/helper/cropImage';
import { backgroundAlignmentOptions } from '../../../../../SharedUI/components/alignmentHelpers';

const marks = {
  1: '0%',
  2: '10%',
  3: '20%',
  4: '30%',
  5: '40%',
  6: '50%',
  7: '60%',
  8: '70%',
  9: '80%'
};

export const ContainerSettings = () => {
  const { actions, query } = useEditor();
  const isMobileView = useIsMobileView();
  const dispatch = useAppDispatch();
  const theme = useCurrentlyEditingBuilderTheme();
  const {
    actions: { setProp },
    currentNode,
    props,
    nodeId,
    SerializedNodes,
    displaySpecialIcon,
    columnGridNodes
  } = useNode(node => {
    const SerializedNodes = query.getSerializedNodes();
    const columnGrid = node.data.nodes[0];
    const column = SerializedNodes[columnGrid]?.nodes?.[0];
    const columnGridNodes = SerializedNodes?.[columnGrid]?.nodes;
    const columnProps = SerializedNodes?.[column]?.props;
    return {
      SerializedNodes: SerializedNodes,
      props: node.data.props,
      nodeId: node.id,
      currentNode: node,
      displaySpecialIcon: !!columnProps?.displaySpecialIcon,
      columnGridNodes: columnGridNodes
    };
  });

  const columnGridNodesLength = columnGridNodes?.length;

  const [selectedTemplate, setSelectedTemplate] = useState<SelectedTemplate>();
  const [selectedTab, setSelectedTab] = useState(ContainerSettingsTab.SETTINGS);
  const noChildPresent = !!!currentNode.data.nodes.length;

  useEffect(() => {
    if (!noChildPresent) {
      return;
    }
    setSelectedTab(ContainerSettingsTab.TEMPLATES);
  }, [noChildPresent]);

  const { id } = getMainContainerNode(query);

  const nodeIds = getRootAndMainContainerNodes(query);

  const layoutsHandler = (columnsCount: number, nodesTypes: string[], key: number) => {
    if (isMobileView) message.info(ContainerMessages.columnsOnMobile);
    const actionCreator = actions.history.throttle();

    if (currentNode.data.nodes.length > 0) {
      handleChangeColumnLayout({
        action: actionCreator,
        key,
        childNodes: [],
        columnsCount,
        nodesTypes,
        parentNodeId: ''
      });
    } else {
      const gridNode = query
        .parseReactElement(<Element id={getUniqueId()} is={ColumnGrid} canvas></Element>)
        .toNodeTree();
      actionCreator.addNodeTree(gridNode, currentNode.id);
      let parentNodeId = gridNode.rootNodeId;
      handleChangeColumnLayout({
        childNodes: [],
        parentNodeId: gridNode.rootNodeId,
        action: actionCreator,
        key,
        columnsCount,
        nodesTypes
      });
    }
    dispatch(
      setMobileView({
        payload: false
      })
    );
    currentNode.dom?.scrollIntoView();
  };
  const onceExistingElements = isProvidedElementExistInFunnel(elementsOnceInFunnel, true);

  const currentPageUserInteractingElements = checkIfProvidedElementsExistsInBuilder(
    query,
    userInteractingComponents
  );
  const handleTemplateSelectionSubmit = (selectedTemplate: SelectedTemplate) => {
    if (
      !canAddTemplateInsideCurrentPage(
        selectedTemplate.nodes,
        onceExistingElements,
        currentPageUserInteractingElements
      )
    ) {
      message.error(UserInteractingComponentsMessages.singleUsageForTemplate);
      return;
    }
    const newNodes: Nodes = {};
    Object.keys(selectedTemplate.nodes).forEach(key => {
      if (selectedTemplate.nodes[key].displayName === CRAFT_ELEMENTS.CONTAINER) {
        selectedTemplate.nodes[key].props.showSaveableModal = false;
      }
      newNodes[key] = query.parseSerializedNode(selectedTemplate.nodes[key]).toNode();
    });

    if (selectedTemplate?.ids) {
      selectedTemplate?.ids.forEach((nodeId, index) => {
        const clonedTree = getClonedTree(
          {
            rootNodeId: nodeId,
            nodes: newNodes
          },
          query
        );

        let actionCreator = actions.history.throttle(500);
        actionCreator.addNodeTree(
          clonedTree,
          id,
          nodeIds.indexOf(currentNode.id) === 0
            ? -1 + index
            : nodeIds.indexOf(currentNode.id) + index - 1
        );
        if (index === 0) {
          actionCreator.delete(currentNode.id);
          actions.selectNode(clonedTree.rootNodeId);
        }
      });
    } else if (selectedTemplate?.id) {
      const clonedTree = getClonedTree(
        {
          rootNodeId: selectedTemplate.id,
          nodes: newNodes
        },
        query
      );

      let actionCreator = actions.history.throttle(500);
      actionCreator.addNodeTree(
        clonedTree,
        id,
        nodeIds.indexOf(currentNode.id) === 0 ? -1 : nodeIds.indexOf(currentNode.id) - 1
      );

      actionCreator.delete(currentNode.id);
      actions.selectNode(clonedTree.rootNodeId);
    }
  };
  const handleSaveAsTemplate = () => {
    actions.history.ignore().setProp(nodeId, (props: any) => {
      return (props.showSaveableModal = true);
    });
  };

  const handleChangeColumnLayout = (data: {
    parentNodeId: string;
    childNodes: string[];
    columnsCount: number;
    nodesTypes: string[];
    key: number;
    action: any;
  }) => {
    const { columnsCount, action, key, nodesTypes } = data;
    let parentNodeId = '';
    let childNodes: string[] = [];

    currentNode.data.nodes.forEach(element => {
      parentNodeId = element;
      childNodes = query.node(parentNodeId).get().data.nodes;
      let childNodesInReversedSeq = JSON.parse(JSON.stringify(childNodes)).reverse();
      if (childNodes.length > columnsCount) {
        let c1 = 0;
        while (c1 < childNodes.length - columnsCount) {
          action.delete(childNodesInReversedSeq[0]);
          childNodesInReversedSeq.shift();
          c1++;
        }
      }
      let c2 = 0;
      while (c2 < columnsCount) {
        childNodes[c2] &&
          copyNodeWithUpdatedProps(
            query,
            action,
            childNodes[c2],
            parentNodeId,
            getColumnProps(key, c2)
          );
        c2++;
      }
      const remainingChildNodesClone = [...childNodesInReversedSeq.reverse()];
      for (let c1 = 0; c1 < nodesTypes.length; c1++) {
        if (!remainingChildNodesClone[c1]) {
          const NewNodeTree = query
            .parseReactElement(
              <Element
                id={getUniqueId()}
                is={Column}
                displaySpecialIcon={displaySpecialIcon}
                {...getColumnProps(key, c1)}
                canvas
              ></Element>
            )
            .toNodeTree();
          action.addNodeTree(NewNodeTree, parentNodeId, c1);
        }
      }
    });
  };

  const handleChangeIconState = (checked: boolean) => {
    const actionCreator = actions.history.throttle();
    actionCreator.setProp(currentNode.id, (props: any) => {
      return (props.displaySpecialIcon = checked);
    });
    let columnNodeId = ' ';
    const containerNodeId = getParentNodeId(nodeId, query, CRAFT_ELEMENTS.CONTAINER);
    const allDescendants = query.node(containerNodeId).descendants(true);
    const serializedNodes = query.getSerializedNodes();
    allDescendants?.map((nodeId: any) => {
      const node = serializedNodes[nodeId];
      actionCreator.setProp(nodeId, (props: any) => {
        return (props.displaySpecialIcon = checked);
      });
      if (node.displayName === CRAFT_ELEMENTS.COLUMN) {
        columnNodeId = nodeId;
      }
      if (
        !checked &&
        node.displayName === CRAFT_ELEMENTS.ICON &&
        node.props.isColumnIcon === true
      ) {
        actionCreator.setProp(
          columnNodeId,
          (props: any) => (props.deletedSpecialIconProps = JSON.stringify(node?.props))
        );
        actionCreator.delete(nodeId);
      }
    });
  };

  return (
    <Col span={24} className="builder__settings-sidebar__container">
      <Card className="settings-card" title="Abschnitt" bordered={false}>
        <Row className="builder__settings-sidebar__row">
          <Tabs
            className="tabs__reset-left-padding w-100"
            activeKey={selectedTab}
            defaultActiveKey={
              noChildPresent ? ContainerSettingsTab.TEMPLATES : ContainerSettingsTab.SETTINGS
            }
            onTabClick={(newSelectedTab: ContainerSettingsTab) => {
              setSelectedTab(newSelectedTab);
            }}
          >
            <TabPane tab={ContainerSettingsTab.SETTINGS} key={ContainerSettingsTab.SETTINGS}>
              <Row className="builder__settings-sidebar__row">
                <Col span={24}>
                  <BuilderSettingsTitle
                    title="Spalten"
                    suffix="(mobil immer untereinander)"
                    infoPopoverIcon={<PlayCircleOutlined />}
                    infoPopoverText={
                      <div>
                        Abschnitte können Spalten enthalten, die auf dem Desktop angezeigt werden.{' '}
                        <a
                          target="_blank"
                          href="https://hilfe.meetovo.de/de/article/abschnitt-und-spalten-erklart-1ee2jbm/"
                        >
                          Video ansehen
                        </a>
                      </div>
                    }
                  />
                </Col>

                <Col span={8}>
                  <Row className="cursor-pointer">
                    <RBCol onClick={() => layoutsHandler(1, [CRAFT_ELEMENTS.COLUMN], 1)}>
                      <div className="block-col-style">1</div>
                    </RBCol>
                  </Row>
                </Col>
                <Col span={8} className="padding-left-15">
                  <Row
                    className="cursor-pointer"
                    onClick={() => layoutsHandler(2, ['ColumnOneByTwo', CRAFT_ELEMENTS.COLUMN], 6)}
                  >
                    <Col span={16}>
                      <div className="block-col-style">2</div>
                    </Col>
                    <Col span={8}>
                      <div className="block-col-style">3</div>
                    </Col>
                  </Row>
                </Col>
                <Col span={8} className="padding-left-15">
                  <Row
                    className="cursor-pointer"
                    onClick={() => layoutsHandler(2, [CRAFT_ELEMENTS.COLUMN, 'ColumnOneByTwo'], 5)}
                  >
                    <Col span={8}>
                      <div className="block-col-style"></div>
                    </Col>
                    <Col span={16}>
                      <div className="block-col-style"></div>
                    </Col>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row
                    className="cursor-pointer"
                    onClick={() =>
                      layoutsHandler(2, [CRAFT_ELEMENTS.COLUMN, CRAFT_ELEMENTS.COLUMN], 2)
                    }
                  >
                    <Col span={12}>
                      <div className="block-col-style"></div>
                    </Col>
                    <Col span={12}>
                      <div className="block-col-style"></div>
                    </Col>
                  </Row>
                </Col>
                <Col span={8} className="padding-left-15">
                  <Row
                    className="cursor-pointer"
                    onClick={() =>
                      layoutsHandler(
                        3,
                        [CRAFT_ELEMENTS.COLUMN, CRAFT_ELEMENTS.COLUMN, CRAFT_ELEMENTS.COLUMN],
                        3
                      )
                    }
                  >
                    <Col span={8}>
                      <div className="block-col-style"></div>
                    </Col>
                    <Col span={8}>
                      <div className="block-col-style"></div>
                    </Col>
                    <Col span={8}>
                      <div className="block-col-style"></div>
                    </Col>
                  </Row>
                </Col>
                <Col span={8} className="padding-left-15">
                  <Row
                    className="cursor-pointer"
                    onClick={() =>
                      layoutsHandler(
                        4,
                        [
                          CRAFT_ELEMENTS.COLUMN,
                          CRAFT_ELEMENTS.COLUMN,
                          CRAFT_ELEMENTS.COLUMN,
                          CRAFT_ELEMENTS.COLUMN
                        ],
                        4
                      )
                    }
                  >
                    <Col span={6}>
                      <div className="block-col-style"></div>
                    </Col>
                    <Col span={6}>
                      <div className="block-col-style"></div>
                    </Col>
                    <Col span={6}>
                      <div className="block-col-style"></div>
                    </Col>
                    <Col span={6}>
                      <div className="block-col-style"></div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="builder__settings-sidebar__row">
                <Col span={24}>
                  <BuilderSettingsTitle title="Ausrichtung" />
                  <ColumnAlignmentButtons
                    align={props.align}
                    onChange={align => {
                      setProp((props: any) => (props.align = align));
                    }}
                    disabled={isMobileView || columnGridNodesLength <= 1}
                  />
                </Col>
              </Row>
              <Row className="builder__settings-sidebar__row">
                <Col span={24}>
                  <BuilderSettingsTitle title="Abstand oben" icon={<FaArrowUp />} />
                  <Slider
                    className="builder-slider-style"
                    marks={distanceAboveMarks}
                    step={50}
                    tooltipVisible={false}
                    defaultValue={50}
                    value={getSliderValueByPaddingClass(props.paddingTopClass, 'top')}
                    onChange={value => {
                      setProp((props: any) => {
                        return (props.paddingTopClass = getPaddingClassBySliderValue(value, 'top'));
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className="builder__settings-sidebar__row">
                <Col span={24}>
                  <BuilderSettingsTitle title="Abstand unten" icon={<FaArrowDown />} />
                  <Slider
                    className="builder-slider-style"
                    marks={distanceBelowMarks}
                    step={50}
                    tooltipVisible={false}
                    defaultValue={50}
                    value={getSliderValueByPaddingClass(props.paddingBottomClass, 'bottom')}
                    onChange={value => {
                      setProp((props: any) => {
                        return (props.paddingBottomClass = getPaddingClassBySliderValue(
                          value,
                          'bottom'
                        ));
                      });
                    }}
                  />
                </Col>
              </Row>

              <Row className="builder__settings-sidebar__row">
                <Col span={22}>
                  <BuilderSettingsTitle title="Linksbündiges Icon" icon={<FaListUl />} />
                </Col>
                <Col span={2}>
                  <Switch
                    size="small"
                    checked={displaySpecialIcon}
                    onChange={handleChangeIconState}
                  />
                </Col>
              </Row>
              <Row className="builder__settings-sidebar__row">
                <Col span={11}>
                  <BuilderSettingsTitle title="Hintergrundfarbe" />
                  <BuilderColorPickerButton
                    showColorTypeBtns
                    color={getElementColor(props.backgroundColor, theme)}
                    onChange={(backgroundColor, colorKey) => {
                      setProp((props: any) => {
                        props.backgroundColor = colorKey || backgroundColor;
                      });
                    }}
                  />
                </Col>
                <Col span={11} offset={1}>
                  <BuilderSettingsTitle title="Vorlage erstellen" />
                  <Button className="mt-1" onClick={handleSaveAsTemplate} icon={<SaveOutlined />}>
                    Abschnitt speichern
                  </Button>
                </Col>
              </Row>
              <Row className="builder__settings-sidebar__row">
                <Col span={24}>
                  <ContainerSettingsSectionId />
                </Col>
              </Row>
            </TabPane>
            <TabPane tab={ContainerSettingsTab.TEMPLATES} key={ContainerSettingsTab.TEMPLATES}>
              <TemplatesPanel
                handleAddTemplate={(serializedNode: SelectedTemplate) => {
                  if (query.node(currentNode.id).get()?.data?.nodes?.length) {
                    setSelectedTemplate(serializedNode);
                    return;
                  }
                  handleTemplateSelectionSubmit(serializedNode);
                }}
              />
            </TabPane>
          </Tabs>
        </Row>
      </Card>
      <Row className="builder__settings-sidebar__row mb-5">
        <Col span={24}>
          {selectedTab === ContainerSettingsTab.SETTINGS && (
            <BuilderSettingsTogglePro
              title="Hintergrundbild"
              rightContent={
                <Switch
                  disabled={false}
                  size="small"
                  checked={props.backgroundImgEnabled}
                  onChange={checked => {
                    setProp((props: any) => (props.backgroundImgEnabled = checked));
                  }}
                />
              }
            />
          )}
          <Row>
            {props.backgroundImgEnabled && selectedTab === ContainerSettingsTab.SETTINGS && (
              <>
                <Col span={24} className="mt-3 px-4">
                  <BuilderSettingsTitle title="Overlay-Transparenz" />
                </Col>
                <Col span={24} className="px-4" data-testid="image-opacity-slider">
                  <Slider
                    className="builder-slider-style"
                    marks={marks}
                    min={1}
                    max={8}
                    tooltipVisible={false}
                    defaultValue={defaultImageOpacity.key}
                    value={getKeyByValue(props.opacity, ImageAvailableOpacity).key}
                    onChange={value => {
                      setProp(
                        (props: any) =>
                          (props.opacity = getValueByKey(
                            value as number,
                            ImageAvailableOpacity
                          ).value)
                      );
                    }}
                  />
                </Col>
                <Col
                  span={24}
                  className={`mt-3 px-4 ${!isMobileView && 'disable-layer__disabled'}`}
                >
                  <BuilderSettingsTitle title="Mindestgröße für Mobile" icon={<FaArrowsAltV />} />
                  <Slider
                    className="builder-slider-style"
                    marks={minHeightMobileMarks}
                    min={1}
                    max={containerMinHeightForMobile.length}
                    tooltipVisible={false}
                    defaultValue={defaultContainerMinHeightForMobile.key}
                    value={
                      getKeyByValue(
                        props.containerMinHeightForMobile,
                        containerMinHeightForMobile,
                        'value'
                      ).key
                    }
                    onChange={value => {
                      setProp(
                        (props: any) =>
                          (props.containerMinHeightForMobile = getValueByKey(
                            value as number,
                            containerMinHeightForMobile
                          ).value)
                      );
                    }}
                  />
                </Col>

                <Col span={24} className={`mt-3 px-4 ${isMobileView && 'disable-layer__disabled'}`}>
                  <BuilderSettingsTitle title="Mindesthöhe für Desktop" icon={<FaArrowsAltV />} />
                  <Slider
                    className="builder-slider-style "
                    marks={minHeightDesktopMarks}
                    min={1}
                    max={containerMinHeightForDesktop.length}
                    tooltipVisible={false}
                    defaultValue={defaultContainerMinHeightForDesktop.key}
                    value={
                      getKeyByValue(props.containerMinHeightDesktop, containerMinHeightForDesktop)
                        .key
                    }
                    onChange={value => {
                      setProp(
                        (props: any) =>
                          (props.containerMinHeightDesktop = getValueByKey(
                            value as number,
                            containerMinHeightForDesktop
                          ).value)
                      );
                    }}
                  />
                </Col>

                <Col span={24} className="mt-3 px-4">
                  <BuilderSettingsTitle
                    title="Vertikale Ausrichtung des Hintergrundbildes"
                    icon={<FaArrowsAltV />}
                  />
                  <ColumnAlignmentButtons
                    align={props.containerBackgroundAlignment}
                    onChange={align => {
                      setProp((props: any) => (props.containerBackgroundAlignment = align));
                    }}
                    customAlignmentOptions={backgroundAlignmentOptions}
                  />
                </Col>
              </>
            )}

            {props.backgroundImgEnabled && selectedTab === ContainerSettingsTab.SETTINGS && (
              <>
                <Col span={24} className="mt-3 px-4">
                  <BuilderSettingsTitle title="Bild" />
                  <ImageUploadV3
                    previewImage={props.backgroundImg}
                    onChange={(url: string) => {
                      setProp((props: any) => {
                        props.backgroundImg = url;
                      });
                    }}
                    minWidth={1000}
                    minHeight={1000}
                    quality={1}
                    uploadedImage
                    showRatioSelector={true}
                    circleEnabled={false}
                    grid
                    restrictPosition={false}
                    freeSelectionEnabled={false}
                    possibleAspectRatios={containerBackgroundAspectRatios}
                  />
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
      <WarningModal
        visible={Boolean(selectedTemplate)}
        toggleModal={() => {
          setSelectedTemplate(undefined);
        }}
        onYes={() => {
          if (selectedTemplate) handleTemplateSelectionSubmit(selectedTemplate);
        }}
        yesButtonText="Ja"
        noButtonText="Nein"
        title="Willst du diesem Abschnitt gegen die Vorlage ersetzen?"
        subTitle={
          <p>
            Der Inhalt in dem aktuell ausgewählten Abschnitt wird gegen den Inhalt der Vorlage
            ersetzt. Wenn du dies nicht willst erstelle einen neuen Abschnitt. Erfahre in{' '}
            <a
              href="https://hilfe.meetovo.de/de/article/abschnitt-und-spalten-erklart-1ee2jbm/"
              target="_blank"
            >
              diesem Video
            </a>
            , wie du mit Abschnitten das perfekte Design erstellst.
          </p>
        }
      />
    </Col>
  );
};

export default ContainerSettings;
