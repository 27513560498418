import React, { useState, useEffect, useRef, lazy } from 'react';
import './App.scss';
import { ConfigProvider, message } from 'antd';
import deDe from 'antd/es/locale/de_DE';
import 'antd/dist/antd.css';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  isTokenValid,
  closeSession,
  getMeetovoAuthData,
  populateLocalStorageWithAuth
} from './Login/container/Login';
import { defaultRoute } from './UI/routes';
import { ApolloProvider, useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import 'moment/locale/de';
import { MockedProvider } from '@apollo/react-testing';
import mockWrapper from './mockWrapper';
import { getAppoloClient } from './Login/helper/login';
import { LoadingOutlined } from '@ant-design/icons';
import { getEnvironment, isCustomDomain, redirectAgencyRootUser } from './helper/environment';
import ReactPixel from 'react-facebook-pixel';
import ErrorBoundary from './GeneralComponents/ErrorBoundary';
import gql from 'graphql-tag';
import { getUrlParam } from './helper/url';
import { Role } from './UI/utils/roleTypes';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import { selectUserData, setUserIsInitializing, useUserIsInitializing } from './UI/redux/userSlice';
import { AppMessages } from './config/messages';
import { navigateAndPreventSameRoute } from './helper/navigation';
import mixpanel from 'mixpanel-browser';
import { getFunnelIdFromToken, mixpanelEvents } from './UI/utils/mixpanel';
import { handleEmptyFunnelRedux } from './Funnel/redux/funnelSlice';
import { clearReduxStore } from './helper/clearReduxStore';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EventsProvider } from './Events/context/EventsContext';
import { selectedFunnelViewSelection } from './UI/redux/uiSlice';
import { useSelector } from 'react-redux';
import { getCompleteSubscriptionInfo } from './UI/redux/userThunk';
import SuspenseLoadingWrapper from './Share/container/SuspenseLoadingWrapper';

const Login = lazy(() => import('./Login/container/Login'));
const CustomerLogin = lazy(() => import('./CustomerLogin/container/CustomerLogin'));
const AutoRegistration = lazy(() => import('./Login/container/AutoRegistration'));
const UIController = lazy(() => import('./UI/container/UIController'));
const HelpButton = lazy(() => import('./HelpButton/container/HelpButton'));
const AgencyDashboardBrandedMetaData = lazy(() =>
  import('./AgencyDashboard/components/AgencyDashboardBrandedMetaData')
);

moment.locale('de');

declare global {
  interface Window {
    handleLogout(): any;
    productFruits?: any;
    apolloClient?: any;
    richTextExtensionErrorIsShowingUp?: boolean;
    richTextMissmatchErrorIsShowingUp?: boolean;
    faitracker: any;
  }
}

const RESET_TRIAL = gql`
  mutation resetTrial($trialResetToken: String!) {
    resetTrial(trialResetToken: $trialResetToken)
  }
`;

const AppActionsInApolloContext = () => {
  const [resetTrial] = useMutation(RESET_TRIAL);
  const trialResetToken = getUrlParam('trial_reset_token');

  useEffect(() => {
    if (trialResetToken) handleResetTrial();
  }, []);

  function handleResetTrial() {
    resetTrial({
      variables: { trialResetToken }
    })
      .then(res => {
        if (isTokenValid()) window.handleLogout();

        message.success(AppMessages.resetTrial.successLoggedout, 8);
      })
      .catch(e => {
        const errorMessage = e.message.replace('GraphQL error: ', '');

        //@ts-ignore
        const thisError = AppMessages.resetTrial.apiErrors[errorMessage];
        if (thisError) message.error(thisError, 8);
        else message.error(AppMessages.resetTrial.error, 8);
      });
  }

  return null;
};

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const funnelView = useSelector(selectedFunnelViewSelection);

  const trialResetToken = getUrlParam('trial_reset_token');
  const isDev = getEnvironment().isDev;
  const useMock = !!localStorage.useMock;
  const isRegister = () => location.pathname.includes('/registrieren') || false;
  const pathname = () => location.pathname || '';
  const [loggedIn, setLoggedIn] = useState(isTokenValid() && !trialResetToken);
  const [client, setClient] = useState<any>();
  const isCheckout = pathname().includes('/checkout');
  const customDomain = isCustomDomain() ? window.location.host : '';
  const isDefaultDomainCustomerLogin = pathname().includes('kunden-login/');
  const isCustomerLogin = isDefaultDomainCustomerLogin || !!customDomain;
  const userData = useAppSelector(selectUserData);
  const isAgencyCustomer = userData?.role === Role.AGENCY_CUSTOMER;
  const isAdmin = userData?.role === Role.ADMIN;
  const isCoach = userData?.role === Role.COACH;
  const startTimeRef = useRef<Date | undefined>();

  const isUserInitializing = useUserIsInitializing();

  if (isDefaultDomainCustomerLogin && (isCoach || isAdmin))
    message.warning(AppMessages.useAgencyDashboardInkognito, 6);

  if (loggedIn && customDomain  && !isUserInitializing && isCoach) {
    redirectAgencyRootUser()
  }

  if (loggedIn && isCustomerLogin && !isUserInitializing && !isCheckout) {
    navigateAndPreventSameRoute(navigate, '/kontakte', { replace: true });
  }

  if (location.pathname.endsWith('/')) {
    if (location.pathname === '/') {
      navigateAndPreventSameRoute(navigate, isAgencyCustomer ? '/kontakte' : '/meine-funnels', {
        replace: true
      });
    } else {
      navigateAndPreventSameRoute(
        navigate,
        location.pathname.substring(0, location.pathname.length - 1) + location.search,
        {
          replace: true
        }
      );
    }
  }

  useEffect(() => {
    if (!isDev) {
      const options = {
        autoConfig: true,
        debug: process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development' // enable logs
      };

      ReactPixel.init('717262752181116', undefined, options);
    }

    const handleOnError = (event: any) => {
      const { message: errorMessage } = event;
      if (
        (errorMessage === 'Uncaught Error: Got unexpected null or undefined' ||
          errorMessage === 'Uncaught Invariant Violation: Invalid DraftEditorContents node.') &&
        !window.richTextExtensionErrorIsShowingUp
      ) {
        window.richTextExtensionErrorIsShowingUp = true;
        message.error(
          'Eine Browser-Erweiterung unterbricht die Verbindung zum Textfeld. Kontaktiere den Support für die mögliche Unterstützung der Erweiterung.',
          10,
          () => {
            window.richTextExtensionErrorIsShowingUp = false;
          }
        );
      }
    };

    window.addEventListener('error', handleOnError);

    return () => {
      window.removeEventListener('error', handleOnError);
    };
  }, []);

  useEffect(() => {
    if (location.pathname.includes('builder')) {
      startTimeRef.current = new Date();
    }
    return () => {
      if (startTimeRef.current) {
        mixpanel.track(mixpanelEvents.TIME_SPEND_INSIDE_BUILDER, {
          funnelId: getFunnelIdFromToken(),
          minutes: moment.duration(moment().diff(startTimeRef.current)).asMinutes()
        });
        startTimeRef.current = undefined;
      }
    };
  }, [location.pathname.includes('builder')]);

  useEffect(() => {
    handleSetAppolloClient();
  }, [loggedIn]);

  useEffect(() => {
    // TO STORE the auth data in Session Storage in case user opens link directly in new tab
    const data = getMeetovoAuthData();
    if (data) {
      populateLocalStorageWithAuth(JSON.parse(data));
      dispatch(getCompleteSubscriptionInfo());
    }
  }, []);

  function handleSetAppolloClient() {
    if (!useMock) {
      const apolloClient = getAppoloClient();
      window.apolloClient = apolloClient;
      setClient(apolloClient);
    }
  }
  async function handleLogin() {
    await dispatch(getCompleteSubscriptionInfo());
    setLoggedIn(true);
    if (isCustomerLogin) {
      localStorage.setItem(
        'meetovoAgencyDashboardPathname',
        location.pathname.replace('/dashboard', '')
      );
      navigateAndPreventSameRoute(navigate, '/kontakte');
    } else {
      navigateAndPreventSameRoute(navigate, defaultRoute);
    }
  }

  window.handleLogout = () => {
    if (userData?.role === Role.AGENCY_CUSTOMER) {
      const meetovoAgencyDashboardPathname = localStorage.getItem('meetovoAgencyDashboardPathname');
      meetovoAgencyDashboardPathname &&
        navigateAndPreventSameRoute(navigate, meetovoAgencyDashboardPathname);
    }

    if (getMeetovoAuthData()) {
      window.$crisp?.push(['do', 'session:reset']);
      window.$crisp?.push(['do', 'chat:hide']);
      window.CRISP_TOKEN_ID = '';
    }

    closeSession();
    clearReduxStore(dispatch);
    setLoggedIn(false);
  };

  if (!client) return <LoadingOutlined className="centered" spin />;

  if (location.pathname.includes('/auto-registration') && !loggedIn) {
    return (
      <SuspenseLoadingWrapper>
        <ApolloProvider client={client}>
          <AutoRegistration
            onFinish={(success: boolean) => {
              if (success) handleLogin();
              else {
                navigateAndPreventSameRoute(navigate, '/registrieren', { replace: true });
                window.location.reload();
              }
            }}
          />
        </ApolloProvider>
      </SuspenseLoadingWrapper>
    );
  }

  if (pathname() === '/' && loggedIn) {
    navigateAndPreventSameRoute(
      navigate,
      userData?.role === Role.AGENCY_CUSTOMER ? '/kontakte' : defaultRoute,
      {
        replace: true
      }
    );
  }

  if (!loggedIn && !isRegister() && !pathname().includes('login') && !isCheckout) {
    navigateAndPreventSameRoute(navigate, 'login' + location.search, { replace: true });
    dispatch(setUserIsInitializing(false));
  }

  if (
    loggedIn &&
    !isCustomerLogin &&
    (location.pathname.includes('/login') || location.pathname.includes('/registrieren'))
  )
    navigateAndPreventSameRoute(navigate, defaultRoute, { replace: true });

  const appContent = (
    <SuspenseLoadingWrapper>
      <ConfigProvider locale={deDe}>
        <EventsProvider currentView={funnelView}>
          <>
            <AppActionsInApolloContext />

            {(isAgencyCustomer || isCustomerLogin) && <AgencyDashboardBrandedMetaData />}

            {(loggedIn || isCheckout) && (
              <>
                <UIController />
                <HelpButton anonymAllowed={isCheckout && !loggedIn} />
              </>
            )}
            {(!loggedIn || (!loggedIn && isRegister())) &&
              !isCheckout &&
              (!isCustomerLogin ? (
                <Login onLogin={handleLogin} />
              ) : (
                <CustomerLogin onLogin={handleLogin} />
              ))}
          </>
        </EventsProvider>
      </ConfigProvider>
    </SuspenseLoadingWrapper>
  );

  return (
    <SuspenseLoadingWrapper>
      <ErrorBoundary>
        <div className="app">
          {useMock ? (
            <MockedProvider mocks={mockWrapper} addTypename={false}>
              {appContent}
            </MockedProvider>
          ) : (
            <ApolloProvider client={client}>{appContent}</ApolloProvider>
          )}
        </div>
      </ErrorBoundary>
    </SuspenseLoadingWrapper>
  );
}

export default App;
