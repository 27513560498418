import React from 'react';
import { useState, useCallback } from 'react';
import { Button, Popover, Input, Typography, Space } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { ToolbarItem } from './EditorItem';
import { editorClasses } from './EditorHelpers';
import { EditorToolbarProps } from './EditorTypes';

export function LinkBlock({ editor }: Pick<EditorToolbarProps, 'editor'>) {
  const [url, setUrl] = useState('');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    const previousUrl = editor?.getAttributes('link').href;

    setAnchorEl(event.currentTarget);

    if (previousUrl) {
      setUrl(previousUrl);
    } else {
      setUrl('');
    }
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleUpdateUrl = useCallback(() => {
    handleClosePopover();

    if (!url) {
      editor
        ?.chain()
        .focus()
        .extendMarkRange('link')
        .unsetLink()
        .run();
    } else {
      editor
        ?.chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run();
    }
  }, [editor, url]);

  if (!editor) {
    return null;
  }

  return (
    <div style={{ display: 'flex' }}>
      <ToolbarItem
        aria-label="Link"
        active={editor.isActive('link')}
        className={editorClasses.toolbar.link}
        onClick={handleOpenPopover}
        icon={<LinkOutlined />}
      />
      <ToolbarItem
        aria-label="Unset link"
        disabled={!editor.isActive('link')}
        className={editorClasses.toolbar.unsetlink}
        onClick={() =>
          editor
            .chain()
            .focus()
            .unsetLink()
            .run()
        }
        icon={<LinkOutlined />}
      />

      <Popover
        id={anchorEl ? 'simple-popover' : undefined}
        trigger="click"
        visible={!!anchorEl}
        onVisibleChange={(visible: any) => {
          if (!visible) handleClosePopover();
        }}
        content={
          <div style={{ padding: '8px' }}>
            <Typography.Text strong>URL</Typography.Text>
            <Space direction="vertical" size="small" style={{ marginTop: 8 }}>
              <Input
                placeholder="Enter URL here..."
                value={url}
                onChange={event => setUrl(event.target.value)}
                style={{ width: 240 }}
              />
              <Button type="primary" onClick={handleUpdateUrl}>
                Anwenden
              </Button>
            </Space>
          </div>
        }
      >
        <div ref={''} />
      </Popover>
    </div>
  );
}
