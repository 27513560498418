import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateNewFolderMessages,
  FunnelFolderMessages,
  FunnelMessages,
  UpdateFolderMessages,
  MoveFunnelToFolderMessage,
  MoveFunnelFromFolderMessage
} from '../../config/messages';
import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { FunnelInterface } from '../components/GeneralSettingsBuilder';
import {
  addFunnelFolderThunk,
  deleteFunnelFolderThunk,
  getAllFunnelFoldersThunk,
  getAllFunnelsThunk,
  getAllWidgetsThunk,
  getFunnelsBookingCountThunk,
  moveFunnelInsideFolderThunk,
  removeFunnelFromFolderThunk,
  removeWidgetFromFolderThunk,
  updateFunnelFolderThunk,
  moveWidgetInsideFolderThunk
} from './thunk';
import { message } from 'antd';
import { fetchFunnelTimeInLocalStorageKey } from '../../SharedUI/components/FetchFunnelsBeforeComponentLoadHOC';
import { DomainStatus } from '../../Connect/components/CustomDomains/Domain';
import { WidgetMessages } from '../../Builder/WidgetsBuilder/config/messages';
import { Widget } from '../../Builder/WidgetsBuilder/interfaces/widgetSliceTypes';

export interface FunnelsState {
  funnels: FunnelInterface[];
  bookingCount: { [funnelId: number]: string };
  funnelFolders: Array<{ id: number; name: string }>;
  widgets: Widget[];
  lastPersistFolderId: string | null;
  loaders: {
    funnels: boolean;
    funnelFolders: boolean;
    widgets?: boolean;
    movingFunnelInsideFolder: boolean;
    removeFunnelFromFolder: boolean;
    removeWidgetFromFolder: boolean;
    movingWidgetInsideFolder: boolean;
  };
  errors: {
    widgets: string;
    funnels: string;
    funnelFolders: string;
  };
  funnelSearchValue: string;
}

const INITIAL_STATE: FunnelsState = {
  funnels: [],
  bookingCount: {},
  funnelFolders: [],
  lastPersistFolderId: null,
  widgets: [
    {
      id: 0,
      craftState: '',
      uniqueIdentifier: '',
      enabled: true,
      folderId: null
    }
  ],
  loaders: {
    funnels: true,
    funnelFolders: false,
    widgets: false,
    movingFunnelInsideFolder: false,
    removeFunnelFromFolder: false,
    removeWidgetFromFolder: false,
    movingWidgetInsideFolder: false
  },
  errors: {
    widgets: '',
    funnels: '',
    funnelFolders: ''
  },
  funnelSearchValue: ''
};

export const funnelsSlice = createSlice({
  name: 'funnels',
  initialState: INITIAL_STATE,
  reducers: {
    resetToInitialFunnelsState: () => {
      return INITIAL_STATE;
    },
    setFunnelSearchValue: (state, action: PayloadAction<string>) => {
      state.funnelSearchValue = action.payload;
    },
    setAllFunnels: (state, action: PayloadAction<{ funnels: any }>) => {
      state.funnels = [...action.payload.funnels];
    },
    addNewFunnelToAllFunnels: (state, action: PayloadAction<{ funnel: any }>) => {
      state.funnels = [action.payload.funnel, ...state.funnels];
    },
    addnewWidgetToAllWidgets: (state, action: PayloadAction<{ widget: any }>) => {
      state.widgets = [action.payload.widget, ...state.widgets];
    },
    handleDeleteWidgetsInSlice: (state, action: PayloadAction<{ widgetId: number }>) => {
      state.widgets = state.widgets.filter(ele => ele.id !== action.payload.widgetId);
    },
    setAllFunnelFolders: (state, action: PayloadAction<{ funnelFolders: any }>) => {
      state.funnelFolders = [...action.payload.funnelFolders];
    },
    handleDeleteFunnelInSlice: (state, action: PayloadAction<{ funnelId: any }>) => {
      state.funnels = state.funnels.filter(ele => ele.id !== action.payload.funnelId);
    },
    handleUpdateFunnelInSlice: (
      state,
      action: PayloadAction<{ title: string; funnelUniqueIdentifier: string; funnelId: number }>
    ) => {
      const index = state.funnels.findIndex(ele => ele.id == action.payload.funnelId);

      if (index > -1) {
        state.funnels[index] = {
          ...state.funnels[index],
          title: action.payload.title,
          uniqueIdentifier: action.payload.funnelUniqueIdentifier
        };
      }
    },

    setFunnelDomainInSlice: (
      state,
      action: PayloadAction<
        {
          domianStatus: string;
          domain: string;
          funnelId: number;
          slug: string;
          status: DomainStatus;
        }[]
      >
    ) => {
      action.payload.forEach(item => {
        const selectedFunnel = state.funnels.find(ele => ele.id === item.funnelId);
        if (selectedFunnel) {
          if (item.status === DomainStatus.UPDATED) {
            selectedFunnel.domain = {
              name: item.domain,
              status: item.domianStatus,
              fullLink: `https://${item.domain}${item.slug}`
            };
          } else if (item.status === DomainStatus.DELETED) {
            selectedFunnel.domain = undefined;
          }
        }
      });
    },

    handleDisableFunnel: (state, action: PayloadAction<{ selectedFunnelId: any }>) => {
      const selectedFunnel = state.funnels.find(ele => ele.id === action.payload.selectedFunnelId);
      selectedFunnel && (selectedFunnel.enabled = false);
    },
    handleEnableFunnel: (
      state,
      action: PayloadAction<{ selectedFunnelId: any; isTrashPage?: boolean }>
    ) => {
      if (action.payload.isTrashPage) {
        const selectedFunnel = state.funnels.filter(
          ele => ele.id !== action.payload.selectedFunnelId
        );
        state.funnels = selectedFunnel;
      } else {
        const selectedFunnel = state.funnels.find(
          ele => ele.id === action.payload.selectedFunnelId
        );
        selectedFunnel && (selectedFunnel.enabled = true);
      }
    },
    handleEnableWidget: (
      state,
      action: PayloadAction<{
        widgetId: number;
        enabled: boolean;
        deletedAt: string | undefined;
        remainingDays: number | undefined;
        isTrashPage?: boolean;
      }>
    ) => {
      if (action.payload.isTrashPage) {
        const selectedWidgets = state.widgets.filter(ele => ele.id !== action.payload.widgetId);
        state.widgets = selectedWidgets;
      } else {
        const selectedWidgets = state.widgets.find(ele => ele.id === action.payload.widgetId);
        if (selectedWidgets) {
          selectedWidgets.enabled = action.payload.enabled;
          selectedWidgets.deletedAt = action.payload.deletedAt;
          selectedWidgets.remainingDays = action.payload.remainingDays;
        }
      }
    },

    handleUpdateWidgetTitle: (
      state,
      action: PayloadAction<{ widgetId: number; title: string }>
    ) => {
      const selectedWidgets = state.widgets.find(ele => ele.id === action.payload.widgetId);
      selectedWidgets && (selectedWidgets.title = action.payload.title);
    },

    handleEmptyFunnelRedux: state => {
      state.funnels = [];
      state.funnelFolders = [];
    },
    setLastPersistFolderId: (state, action: PayloadAction<{ folderId: string | null }>) => {
      state.lastPersistFolderId = action.payload.folderId;
    }
  },
  extraReducers: {
    // @ts-ignore
    [getAllFunnelsThunk.pending]: (state: FunnelsState, action: any) => {
      state.loaders.funnels = true;
    },
    // @ts-ignore
    [getAllFunnelsThunk.fulfilled]: (state: FunnelsState, action: any) => {
      state.funnels = action.payload;
      state.loaders.funnels = false;
      localStorage.setItem(fetchFunnelTimeInLocalStorageKey, String(new Date().getTime()));
    },
    // @ts-ignore
    [getAllFunnelsThunk.rejected]: (state: FunnelsState, action: any) => {
      state.errors.funnels = FunnelMessages.getAllFunnelsError;
      state.loaders.funnels = false;
    },
    //@ts-ignore
    [getAllWidgetsThunk.pending]: (state: FunnelsState, action: any) => {
      state.loaders.widgets = true;
    },
    // @ts-ignore
    [getAllWidgetsThunk.fulfilled]: (state: FunnelsState, action: any) => {
      state.widgets = action.payload;
      state.loaders.widgets = false;
    },
    //@ts-ignore
    [getAllWidgetsThunk.rejected]: (state: FunnelsState, action: any) => {
      state.errors.widgets = WidgetMessages.getAllWidgetsError;
      state.loaders.widgets = false;
    },

    // @ts-ignore
    [getAllFunnelFoldersThunk.pending]: (state: FunnelsState, action: any) => {
      state.loaders.funnelFolders = true;
    },
    // @ts-ignore
    [getAllFunnelFoldersThunk.fulfilled]: (state: FunnelsState, action: any) => {
      state.funnelFolders = action.payload;
      state.loaders.funnelFolders = false;
    },
    // @ts-ignore
    [getAllFunnelFoldersThunk.rejected]: (state: FunnelsState, action: any) => {
      state.errors.funnelFolders = 'Could not get all funnel funnel folders';
      state.loaders.funnelFolders = false;
    },
    // @ts-ignore
    [addFunnelFolderThunk.pending]: (state: FunnelsState, action: any) => {
      state.loaders.funnelFolders = true;
    },
    // @ts-ignore
    [addFunnelFolderThunk.fulfilled]: (state: FunnelsState, action: any) => {
      state.funnelFolders = [...state.funnelFolders, action.payload];
      state.loaders.funnelFolders = false;
      message.success(CreateNewFolderMessages.folderCreatedSuccessfully);
    },
    // @ts-ignore
    [addFunnelFolderThunk.rejected]: (state: FunnelsState) => {
      message.error(CreateNewFolderMessages.error);
      state.loaders.funnelFolders = false;
    },
    // @ts-ignore
    [updateFunnelFolderThunk.pending]: (state: FunnelsState) => {
      state.loaders.funnelFolders = true;
    },
    // @ts-ignore
    [updateFunnelFolderThunk.fulfilled]: (state: FunnelsState, action: any) => {
      const currentFolder = state.funnelFolders.find(ele => ele.id === action.payload.id);
      currentFolder && (currentFolder.name = action.payload.name);
      state.loaders.funnelFolders = false;
      message.success(UpdateFolderMessages.folderUpdatedSuccessfully);
    },
    // @ts-ignore
    [updateFunnelFolderThunk.rejected]: (state: FunnelsState) => {
      message.error(UpdateFolderMessages.error);
      state.loaders.funnelFolders = false;
    },
    // @ts-ignore
    [moveFunnelInsideFolderThunk.pending]: (state: FunnelsState) => {
      state.loaders.movingFunnelInsideFolder = true;
    },
    // @ts-ignore
    [moveFunnelInsideFolderThunk.fulfilled]: (
      state: FunnelsState,
      action: PayloadAction<{ folderId: number; movingFunnelId: number }>
    ) => {
      const selectedFunnel = state.funnels.find(ele => ele.id === action.payload.movingFunnelId);
      selectedFunnel && (selectedFunnel.funnelFolderId = action.payload.folderId);
      state.loaders.movingFunnelInsideFolder = false;
      message.success(MoveFunnelToFolderMessage.moveSuccessfully);
    },
    // @ts-ignore
    [moveFunnelInsideFolderThunk.rejected]: (state: FunnelsState) => {
      message.error(MoveFunnelToFolderMessage.funnelNotMove);
      state.loaders.movingFunnelInsideFolder = false;
    },

    // @ts-ignore
    [moveWidgetInsideFolderThunk.pending]: (state: FunnelsState) => {
      state.loaders.movingWidgetInsideFolder = true;
    },
    // @ts-ignore
    [moveWidgetInsideFolderThunk.fulfilled]: (
      state: FunnelsState,
      action: PayloadAction<{ widgetId: number; movingWidgetId: number }>
    ) => {
      const selectedWidget = state.widgets.find(ele => ele.id === action.payload.movingWidgetId);
      selectedWidget && (selectedWidget.folderId = action.payload.widgetId);
      state.loaders.movingWidgetInsideFolder = false;
      message.success(WidgetMessages.moveWidgetToFolderSuccessfully);
    },
    // @ts-ignore
    [moveWidgetInsideFolderThunk.rejected]: (state: FunnelsState) => {
      message.error(WidgetMessages.widgetNotMove);
      state.loaders.movingWidgetInsideFolder = false;
    },
    // @ts-ignore
    [removeFunnelFromFolderThunk.pending]: (state: FunnelsState) => {
      state.loaders.removeFunnelFromFolder = true;
    },
    // @ts-ignore
    [removeFunnelFromFolderThunk.fulfilled]: (
      state: FunnelsState,
      action: PayloadAction<{ funnelId: number }>
    ) => {
      const selectedFunnel = state.funnels.find(ele => ele.id === action.payload.funnelId);
      selectedFunnel && (selectedFunnel.funnelFolderId = null);
      state.loaders.removeFunnelFromFolder = false;
      message.success(MoveFunnelFromFolderMessage.moveSuccessfully);
    },
    // @ts-ignore
    [removeFunnelFromFolderThunk.rejected]: (state: FunnelsState) => {
      message.error(MoveFunnelFromFolderMessage.funnelNotMove);
      state.loaders.removeFunnelFromFolder = false;
    },
    // @ts-ignore
    [removeWidgetFromFolderThunk.pending]: (state: FunnelsState) => {
      state.loaders.removeWidgetFromFolder = true;
    },
    // @ts-ignore
    [removeWidgetFromFolderThunk.fulfilled]: (
      state: FunnelsState,
      action: PayloadAction<{ widgetId: number }>
    ) => {
      const selectedWidget = state.widgets.find(ele => ele.id === action.payload.widgetId);
      selectedWidget && (selectedWidget.folderId = null);
      state.loaders.removeWidgetFromFolder = false;
      message.success(WidgetMessages.moveWidgetFromFolderMessageSuccessfully);
    },
    // @ts-ignore
    [removeWidgetFromFolderThunk.rejected]: (state: FunnelsState) => {
      message.error(WidgetMessages.widgetNotMove);
      state.loaders.removeWidgetFromFolder = false;
    },
    // @ts-ignore
    [deleteFunnelFolderThunk.fulfilled]: (
      state: FunnelsState,
      action: PayloadAction<{ selectedFolderId: number }>
    ) => {
      state.funnels = state.funnels.filter(
        funnel => funnel.funnelFolderId !== action.payload.selectedFolderId
      );

      state.widgets = state.widgets.filter(
        widget => widget.folderId !== action.payload.selectedFolderId
      );
      state.funnelFolders = state.funnelFolders.filter(
        ele => ele.id !== action.payload.selectedFolderId
      );
    },
    // @ts-ignore
    [deleteFunnelFolderThunk.rejected]: () => {
      message.error(FunnelFolderMessages.folderDeleteFailed);
    },
    // @ts-ignore
    [getFunnelsBookingCountThunk.fulfilled]: (
      state: FunnelsState,
      action: PayloadAction<{ [funnelId: number]: string }>
    ) => {
      state.bookingCount = action.payload;
    }
  }
});
export const {
  resetToInitialFunnelsState,
  setAllFunnelFolders,
  setAllFunnels,
  handleDeleteFunnelInSlice,
  handleDeleteWidgetsInSlice,
  handleDisableFunnel,
  handleEnableFunnel,
  handleEnableWidget,
  setFunnelSearchValue,
  handleEmptyFunnelRedux,
  setLastPersistFolderId,
  addNewFunnelToAllFunnels,
  addnewWidgetToAllWidgets,
  handleUpdateFunnelInSlice,
  setFunnelDomainInSlice,
  handleUpdateWidgetTitle
} = funnelsSlice.actions;

export const selectFunnelSearchValue = (state: RootState) => state.funnels.funnelSearchValue;

export const useAllFunnels = () => useAppSelector((state: RootState) => state.funnels.funnels);
export const useAllWidgets = () => useAppSelector((state: RootState) => state.funnels.widgets);

export const useWidgetById = (widgetId: number) =>
  useAppSelector((state: RootState) =>
    state.funnels.widgets.find(item => {
      return item.id === widgetId;
    })
  );

export const useAllActiveFunnels = () =>
  useAppSelector((state: RootState) => state.funnels.funnels.filter(e => e.enabled));

export const useAllActiveFunnelsIDs = () =>
  useAppSelector((state: RootState) =>
    state.funnels.funnels.filter(e => e.enabled).map(funnel => funnel.id)
  );

export const useAllFunnelsLoading = () =>
  useAppSelector((state: RootState) => state.funnels.loaders.funnels);

export const useAllWidgetsLoading = () =>
  useAppSelector((state: RootState) => state.funnels.loaders.widgets);

export const useAllFunnelFolders = () =>
  useAppSelector((state: RootState) => state.funnels.funnelFolders);
export const useAllFunnelFoldersLoading = () =>
  useAppSelector((state: RootState) => state.funnels.loaders.funnelFolders);

export const useFunnelsError = () =>
  useAppSelector((state: RootState) => state.funnels.errors.funnels);
export const useFunnelFoldersError = () =>
  useAppSelector((state: RootState) => state.funnels.errors.funnelFolders);

export const useAllSortedFunnelFolders = () =>
  useAppSelector((state: RootState) =>
    [...state.funnels.funnelFolders].sort((a, b) => a.name.localeCompare(b.name))
  );
export const returnUrlToMyFunnels = () =>
  useAppSelector((state: RootState) =>
    state.funnels.lastPersistFolderId
      ? `/meine-funnels/${state.funnels.lastPersistFolderId}`
      : '/meine-funnels'
  );
export const useMovingFunnelInsideFolderLoading = () =>
  useAppSelector((state: RootState) => state.funnels.loaders.movingFunnelInsideFolder);

export const useMovingWidgetInsideFolderLoading = () =>
  useAppSelector((state: RootState) => state.funnels.loaders.movingWidgetInsideFolder);

export const useRemoveWidgetFromFolderLoading = () =>
  useAppSelector((state: RootState) => state.funnels.loaders.removeWidgetFromFolder);

export const useRemoveFunnelFromFolderLoading = () =>
  useAppSelector((state: RootState) => state.funnels.loaders.removeFunnelFromFolder);

export const useFunnelBookingCount = (funnelId: number) =>
  useAppSelector((state: RootState) => state.funnels.bookingCount[funnelId]);

export default funnelsSlice.reducer;
