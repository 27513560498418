import gql from 'graphql-tag';
import { ADD_ON_KEY } from '../config/types';

export const CANCEL_ADD_ON = gql`
  mutation cancelAddOn($key: ADD_ON_KEY!) {
    cancelAddOn(key: $key) {
      addOnId
      valid
      validUntil
      isActivated
    }
  }
`;

export default function cancelAddOnAPI(key: ADD_ON_KEY) {
  return window.apolloClient.mutate({
    mutation: CANCEL_ADD_ON,
    variables: { key }
  });
}
