import gql from 'graphql-tag';
import { ADD_ON_KEY, AddOnPayload } from '../config/types';

export const SUBSCRIBE_ADD_ON = gql`
  mutation subscribeAddOn($key: ADD_ON_KEY!) {
    subscribeAddOn(key: $key) {
      addOnId
      valid
      validUntil
      isActivated
    }
  }
`;

export default function subscribeAddOnAPI(key: ADD_ON_KEY) {
  return window.apolloClient.mutate({
    mutation: SUBSCRIBE_ADD_ON,
    variables: { key }
  });
}
