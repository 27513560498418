import React, { useEffect, useState } from 'react';
import { Comment, Tooltip, List, Avatar as AntAvatar, Typography, message } from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment';
import Avatar from 'react-avatar';
import { scrollToTop } from '../utils/scrollToTop';
import { BookingNoteResponseType, BookingNoteType } from '../utils/responseTypes';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { GeneralMessages } from '../../config/messages';
import { Editor } from '../../SharedUI/components/Editor';
import SingleNote from './Event/SingleNote';
import { SET_BOOKING_NOTE } from '../graphql/setBookingNoteMutation';

const GET_BOOKING_NOTES = gql`
  query($id: Int!) {
    getBookingNotes(input: $id) {
      id
      coachId
      note
      createdAt
      partialUser {
        firstName
        lastName
        imageURL
      }
    }
  }
`;

const REMOVE_BOOKING_NOTE = gql`
  mutation($id: Int!) {
    removeBookingNote(input: $id)
  }
`;
interface NotesPropTypes {
  eventId: number;
}
const Notes: React.FC<NotesPropTypes> = ({ eventId }) => {
  const { data: bookingNoteData, error, refetch: refetchNotes } = useQuery<BookingNoteResponseType>(
    GET_BOOKING_NOTES,
    {
      variables: {
        id: eventId
      }
    }
  );
  const [setBookingNote, { loading: setNoteLoading }] = useMutation(SET_BOOKING_NOTE);
  const [removeBookingNote, { loading: removeNoteLoading }] = useMutation(REMOVE_BOOKING_NOTE);
  const { firstName = '', lastName = '', imageURL = '', id } = useCurrentUser();
  const isProfileImagePlaceholder = imageURL.includes('placeholder');

  const [notes, setNotes] = useState<BookingNoteType[]>([]);
  const [noteToBeDeleted, setNoteToBeDeleted] = useState<number>();

  useEffect(() => {
    refetchNotes();
    setNotes(bookingNoteData?.getBookingNotes || []);
  }, [eventId, bookingNoteData?.getBookingNotes.length]);

  const handleAddNote = async (note: string, id?: number) => {
    try {
      const { data } = await setBookingNote({
        variables: {
          input: {
            ...(id && { id }),
            bookingId: eventId,
            note
          }
        }
      });

      data.setBookingNote.partialUser = {
        firstName,
        lastName,
        imageURL
      };

      setNotes(prevNotes => {
        let isEditNote = false;
        const updatedNotes = prevNotes.map(note => {
          if (note.id === data.setBookingNote.id) {
            isEditNote = true;
            return { ...note, note: data.setBookingNote.note };
          }
          return note;
        });
        if (isEditNote) {
          return updatedNotes;
        } else {
          return [data.setBookingNote, ...updatedNotes];
        }
      });
    } catch (error) {
      message.error(GeneralMessages.error);
      console.log('Error creating booking note', error);
    }

    if (bookingNoteData?.getBookingNotes.length) {
      scrollToTop('#notes-list > div.ant-spin-nested-loading > div > ul');
    }
  };

  const handleDeleteNote = (id: number) => {
    removeBookingNote({
      variables: {
        id
      }
    })
      .then(data => {
        setNotes(notes.filter(single => single.id !== id));
        setNoteToBeDeleted(-1);
        message.success(GeneralMessages.success);
      })
      .catch(() => {
        message.error(GeneralMessages.error);
      });
  };

  return (
    <div className="notes">
      <Typography.Title level={4}>Notizen</Typography.Title>
      <List
        locale={{
          emptyText: <div style={{ display: 'none' }}></div>
        }}
        className="notes__list"
        id="notes-list"
        itemLayout="horizontal"
        dataSource={notes}
        renderItem={item => {
          const isOwner = item.coachId === id;
          const { firstName = '', lastName = '', imageURL = '' } = item?.partialUser || {};
          const isProfileImagePlaceholder = imageURL.includes('placeholder');
          return (
            <li key={item.id}>
              <Comment
                author={`${firstName} ${lastName}`}
                avatar={
                  isProfileImagePlaceholder ? (
                    <Avatar
                      name={`${firstName} ${lastName}`}
                      size="30"
                      textSizeRatio={2}
                      round={true}
                    />
                  ) : (
                    imageURL
                  )
                }
                content={
                  <SingleNote
                    handleDeleteNote={handleDeleteNote}
                    noteObject={item}
                    removeNoteLoading={removeNoteLoading}
                    setNoteToBeDeleted={setNoteToBeDeleted}
                    handleAddNote={handleAddNote}
                  />
                }
                datetime={
                  <Tooltip
                    title={moment(item.createdAt)
                      .subtract(0, 'days')
                      .format('YYYY-MM-DD HH:mm')}
                  >
                    <span>
                      {moment(item.createdAt)
                        .subtract(0, 'days')
                        .fromNow()}
                    </span>
                  </Tooltip>
                }
              />
            </li>
          );
        }}
      />

      <Comment
        className="notes__add"
        avatar={
          isProfileImagePlaceholder ? (
            <div>
              <Avatar name={`${firstName} ${lastName}`} size="30" textSizeRatio={2} round={true} />
            </div>
          ) : (
            <AntAvatar src={imageURL} />
          )
        }
        content={<Editor isSubmitting={setNoteLoading} onSubmit={handleAddNote} />}
      />
    </div>
  );
};

export default Notes;
