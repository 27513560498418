import React from 'react';
import { Card, Form, Input } from 'antd';
import FunnelSelector from '../../../SharedUI/components/FunnelSelector';
import DeleteButtonWithConfirmation from '../../../SharedUI/components/DeleteButtonWithConfirmation';
import { FormInstance } from 'antd/lib/form';
import { FunnelSelectionValidator, SlugValidatior } from '../../utils/domainAndSlugValidation';
import { forceLowerCase } from '../../utils/domainInput';
import { useAllFunnels } from '../../../Funnel/redux/funnelSlice';

export interface SlugInterface {
  id: number;
  funnelId: number;
  slug: string;
}

interface SlugProps extends SlugInterface {
  domainName: string;
  visible: boolean;
  formInstance: FormInstance;
  onDelete(id: number): void;
  isDisabled?: boolean;
}

const Slug = ({
  domainName,
  funnelId,
  id,
  formInstance,
  onDelete,
  visible,
  isDisabled
}: SlugProps) => {
  const funnels = useAllFunnels();

  return (
    <Card
      className="ui__white-round-wrapper"
      style={{ marginBottom: '1em', display: visible ? ' none ' : 'block' }}
    >
      <Form.Item label="Slug">
        <Form.Item noStyle>
          <Input
            style={{ width: '35%' }}
            disabled
            data-testid="editSubDomainInput"
            value={domainName}
          />
        </Form.Item>
        <Form.Item required noStyle rules={SlugValidatior} name={`slug_${id}`}>
          <Input
            style={{ width: '65%' }}
            disabled={isDisabled}
            onInput={forceLowerCase}
            name={`slug_${id}`}
            data-testid="eidtDomainSlugInput"
          />
        </Form.Item>
      </Form.Item>
      <Form.Item required rules={FunnelSelectionValidator} name={`funnelId_${id}`} label={'Funnel'}>
        <FunnelSelector
          placeholder="Wähle einen Funnel"
          defaultValue={[funnelId]}
          onChange={selectedFunnelId => {
            formInstance.setFieldsValue({
              [`funnelId_${id}`]: selectedFunnelId
            });
          }}
          customFunnels={[
            {
              title: 'Ohne Funnel',
              id: -1
            }
          ]}
          initialData={funnels}
          allSelectdable={false}
        />
      </Form.Item>
      <Form.Item style={{ display: 'none' }} name={`tempId_${id}`}>
        <Input />
      </Form.Item>
      <DeleteButtonWithConfirmation loading={false} onDelete={() => onDelete(id)} />
    </Card>
  );
};

export default Slug;
