import React, { lazy } from 'react';
import {
  AreaChartOutlined,
  FormOutlined,
  EditOutlined,
  UserOutlined,
  FunnelPlotOutlined,
  AppstoreOutlined,
  PlayCircleOutlined,
  ShareAltOutlined,
  LinkOutlined,
  TeamOutlined,
  ShoppingCartOutlined
} from '@ant-design/icons';
import { Alert } from 'antd';
import { RouteMessages } from '../config/messages';
import { availableLayouts, SingleRouteType } from './utils/routeTypes';
import { Role } from './utils/roleTypes';
import BuilderLayoutScreen from '../Builder/container/BuilderScreen';

const AdBuilderLayoutScreen = lazy(() => import('../Builder/AdBuilder/container/AdBuilderScreen'));
const WidgetBuilderLayoutScreen = lazy(() =>
  import('../Builder/WidgetsBuilder/container/WidgetBuilderScreen')
);
const AccountOverview = lazy(() => import('../AccountOverview/container/AccountOverview'));
const AgencyDashboard = lazy(() => import('../AgencyDashboard/container/AgencyDashboard'));
const Analytics = lazy(() => import('../Analytics/container/Analytics'));
const Checkout = lazy(() => import('../Checkout/container/Checkout'));
const CustomerManagement = lazy(() => import('../CustomerManagement/container/CustomerManagement'));
const ConnectMainContainer = lazy(() => import('../Connect/container/ConnectMainContainer'));
const Marketplace = lazy(() => import('../Marketplace/Marketplace'));
const EventsContainer = lazy(() => import('../Events/container/EventsContainer'));
const FunnelOverview = lazy(() => import('../Funnel/container/FunnelOverview'));
const FunnelBuilder = lazy(() => import('../Funnel/container/FunnelBuilder'));
const FirstBoarding = lazy(() => import('../OnBoarding/components/FirstBoarding'));
const FolderInternal = lazy(() => import('../Funnel/container/FolderInternal'));
const LearningCenter = lazy(() => import('../LearningCenter/container/LearningCenterOverview'));
const VideoModuleDetail = lazy(() => import('../LearningCenter/container/VideoModuleDetail'));
const OurDashboard = lazy(() => import('../OurDashboard/container/OurDashboard'));
const Share = lazy(() => import('../Share/container/Share'));
const SecondBoarding = lazy(() => import('../OnBoarding/components/SecondBoarding'));
const WidgetShare = lazy(() => import('../WidgetShare/container/WidgetShare'));
import { AgencyOSBuilderLayoutScreen } from '../Builder/AgencyOSBuilder/container/BuilderScreen';
import { AGENCY_OS_BUILDER_PATH_NAME } from './paths';
import Customer from '../AgencyDashboard/container/Customer';
import AdJobBuilderLayoutScreen from '../Builder/AdJobBuilder/container/AdJobBuilderLayoutScreen';
import { widgetBuilderBasePath } from '../Builder/WidgetsBuilder/helper/helper';
import { IoVideocamOutline } from 'react-icons/io5';

export const defaultRoute = '/meine-funnels';

const routes: SingleRouteType[] = [
  {
    title: 'Meine Funnels',
    path: '/meine-funnels',
    icon: <FunnelPlotOutlined />,
    component: <FunnelOverview />,
    layout: availableLayouts.UI,
    allowedRoles: [Role.ADMIN, Role.COACH]
  },
  {
    path: '/meine-funnels/:id',
    component: <FolderInternal />,
    layout: availableLayouts.UI,
    allowedRoles: [Role.ADMIN, Role.COACH]
  },
  {
    title: 'Bewerbungen',
    path: '/kontakte',
    icon: <UserOutlined />,
    component: <EventsContainer />,
    layout: availableLayouts.UI,
    allowedRoles: [Role.ADMIN, Role.COACH, Role.AGENCY_CUSTOMER]
  },
  {
    title: 'Bearbeiten',
    path: '/bearbeiten',
    icon: <EditOutlined />,
    component: <FunnelBuilder />,
    topLayer: true,
    layout: availableLayouts.UI,
    allowedRoles: [Role.ADMIN, Role.COACH]
  },
  {
    title: 'Builder',
    path: '/builder/*',
    icon: <LinkOutlined />,
    component: <BuilderLayoutScreen />,
    topLayer: true,
    layout: availableLayouts.UI,
    allowedRoles: [Role.ADMIN, Role.COACH]
  },
  {
    title: 'Ad Builder',
    path: '/ad-builder/*',
    icon: <LinkOutlined />,
    component: <AdBuilderLayoutScreen />,
    topLayer: true,
    layout: availableLayouts.UI,
    allowedRoles: [Role.ADMIN, Role.COACH]
  },
  {
    title: 'Ad Job',
    path: '/google-job-builder/*',
    icon: <LinkOutlined />,
    component: <AdJobBuilderLayoutScreen />,
    topLayer: true,
    layout: availableLayouts.UI,
    allowedRoles: [Role.ADMIN, Role.COACH]
  },
  {
    title: 'Widget Builder',
    path: `/${widgetBuilderBasePath}/:id/*`,
    icon: <></>,
    component: <WidgetBuilderLayoutScreen />,
    topLayer: true,
    layout: availableLayouts.UI,
    allowedRoles: [Role.ADMIN, Role.COACH]
  },
  {
    title: 'Share',
    path: '/share',
    icon: <ShareAltOutlined />,
    component: <Share />,
    topLayer: true,
    layout: availableLayouts.UI,
    allowedRoles: [Role.ADMIN, Role.COACH]
  },
  {
    title: 'Share',
    path: '/share/widget/:widgetId',
    icon: <ShareAltOutlined />,
    component: <WidgetShare />,
    topLayer: true,
    layout: availableLayouts.UI,
    allowedRoles: [Role.ADMIN, Role.COACH]
  },
  {
    title: 'Analyse',
    path: '/analyse',
    icon: <AreaChartOutlined />,
    component: <Analytics />,
    topLayer: true,
    layout: availableLayouts.UI,
    allowedRoles: [Role.ADMIN, Role.COACH]
  },
  {
    title: 'Connect',
    path: '/connect',
    icon: <LinkOutlined />,
    component: <ConnectMainContainer />,
    layout: availableLayouts.UI,
    allowedRoles: [Role.ADMIN, Role.COACH]
  },
  {
    title: 'Agency-Dashboard',
    path: '/agency-dashboard',
    icon: <AppstoreOutlined />,
    component: <AgencyDashboard />,
    layout: availableLayouts.UI,
    allowedRoles: [Role.ADMIN, Role.COACH]
  },
  //
  // Keeping these for later use, and current release
  //
  // {
  //   title: 'AgencyOS',
  //   path: `${AGENCY_OS_BUILDER_PATH_NAME.root}/*`,
  //   component: <AgencyOSBuilderLayoutScreen />,
  //   icon: <FormOutlined />,
  //   layout: availableLayouts.UI,
  //   allowedRoles: [Role.ADMIN, Role.COACH],
  //   subRoutes: [
  //     {
  //       title: 'Kunden',
  //       path: '/agency-dashboard/customer',
  //       component: <Customer />,
  //       layout: availableLayouts.UI,
  //       allowedRoles: [Role.ADMIN, Role.COACH]
  //     },
  //     {
  //       title: 'Dashboard',
  //       path: `${AGENCY_OS_BUILDER_PATH_NAME.root}/${AGENCY_OS_BUILDER_PATH_NAME.loginSettings}`,
  //       component: <AgencyOSBuilderLayoutScreen />,
  //       layout: availableLayouts.UI,
  //       allowedRoles: [Role.ADMIN, Role.COACH]
  //     }
  //   ]
  // },
  {
    title: 'Mein Account',
    path: '/account',
    component: <AccountOverview />,
    layout: availableLayouts.UI,
    allowedRoles: [Role.ADMIN, Role.COACH, Role.SUPPORT_MANAGER]
  },
  {
    title: 'Unser Dashboard',
    path: '/unser-dashboard',
    component: <OurDashboard />,
    layout: availableLayouts.UI,
    allowedRoles: [Role.ADMIN, Role.COACH]
  },
  {
    title: 'Checkout',
    path: '/checkout',
    component: <Checkout />,
    layout: availableLayouts.EmptyUi,
    allowedRoles: [Role.ADMIN, Role.COACH]
  },
  {
    title: 'Recruiting Academy',
    path: '/academy',
    icon: <PlayCircleOutlined />,
    component: <LearningCenter />,
    layout: availableLayouts.UI,
    allowedRoles: [Role.ADMIN, Role.COACH]
  },
  {
    title: 'Recruiting Academy',
    path: '/academy/:moduleId',
    component: <VideoModuleDetail />,
    layout: availableLayouts.UI,
    allowedRoles: [Role.ADMIN, Role.COACH]
  },
  {
    title: 'Marketplace',
    path: '/marketplace',
    icon: <ShoppingCartOutlined />,
    component: <Marketplace />,
    layout: availableLayouts.UI,
    allowedRoles: [Role.ADMIN, Role.COACH]
  },
  {
    path: '/onboarding-einfuehrung',
    component: <FirstBoarding />,
    layout: availableLayouts.UI,
    allowedRoles: [Role.ADMIN, Role.COACH, Role.AGENCY_CUSTOMER]
  },
  {
    path: '/onboarding-fragen',
    component: <SecondBoarding />,
    layout: availableLayouts.UI,
    allowedRoles: [Role.ADMIN, Role.COACH]
  },
  {
    title: 'Kundenverwaltung',
    icon: <TeamOutlined />,
    path: '/customer-management',
    component: <CustomerManagement />,
    layout: availableLayouts.UI,
    allowedRoles: [Role.ADMIN, Role.SUPPORT_MANAGER]
  },
  {
    title: "Papierkorb",
    path: '/papierkorb',
    component: <FunnelOverview />,
    layout: availableLayouts.UI,
    allowedRoles: [Role.ADMIN, Role.COACH]
  },
  {
    title: 'Seite nicht gefunden',
    path: '*',
    component: <Alert message={RouteMessages.notFoundError} type="warning" showIcon />,
    layout: availableLayouts.BlankUi,
    allowedRoles: [Role.ADMIN, Role.COACH, Role.AGENCY_CUSTOMER, Role.SUPPORT_MANAGER]
  }
];

export default routes;
