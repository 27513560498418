import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const GET_ALL_ADD_ONS = gql`
  query getAllAddOns {
    getAllAddOns {
      id
      key
      permission {
        valid
        validUntil
        isActivated
      }
    }
  }
`;

export default function getAllAddOnsAPI() {
  return window.apolloClient.query({
    query: GET_ALL_ADD_ONS,
    variables: {}
  });
}
