import React from 'react';
import { Space } from 'antd';
import { editorClasses } from './EditorHelpers';
import { LinkBlock } from './LinkBlock';
import { EditorToolbarProps } from './EditorTypes';
import { ToolbarItem } from './EditorItem';
import { HeadingBlock } from './HeadingBlock';
import {
  BoldOutlined,
  ItalicOutlined,
  StrikethroughOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
  AlignLeftOutlined,
  AlignCenterOutlined,
  AlignRightOutlined,
  FontColorsOutlined
} from '@ant-design/icons';
import { BiAlignJustify, BiUnderline } from 'react-icons/bi';
import ColorPicker from '../../../Funnel/components/subcomponents/ColorPicker';

export function Toolbar({ editor, fullItem }: EditorToolbarProps) {
  if (!editor) {
    return null;
  }

  const currentTextColor = editor.getAttributes('textStyle').color;

  return (
    <Space className={editorClasses.toolbar.root} direction="horizontal" size="small">
      <HeadingBlock editor={editor} />

      <Space size="small">
        <ToolbarItem
          aria-label="Bold"
          active={editor.isActive('bold')}
          className={editorClasses.toolbar.bold}
          onClick={() =>
            editor
              .chain()
              .focus()
              .toggleBold()
              .run()
          }
          icon={<BoldOutlined />}
        />
        <ToolbarItem
          aria-label="Italic"
          active={editor.isActive('italic')}
          className={editorClasses.toolbar.italic}
          onClick={() =>
            editor
              .chain()
              .focus()
              .toggleItalic()
              .run()
          }
          icon={<ItalicOutlined />}
        />
        <ToolbarItem
          aria-label="Underline"
          active={editor.isActive('underline')}
          className={editorClasses.toolbar.strike}
          onClick={() =>
            editor.extensionManager.editor
              .chain()
              .focus()
              // @ts-ignore
              .toggleUnderline()
              .run()
          }
          icon={<BiUnderline />}
        />
        <ColorPicker
          color={currentTextColor}
          onChange={color => {
            editor
              .chain()
              .focus()
              .setColor(color)
              .run();
          }}
          className="custom-color-picker-popup"
          palletteClassName="custom-color-picker-container"
        >
          <ToolbarItem
            aria-label="Color"
            icon={<FontColorsOutlined style={{ color: currentTextColor }} />}
          />
        </ColorPicker>
      </Space>

      <Space size="small">
        <ToolbarItem
          aria-label="Bullet list"
          active={editor.isActive('bulletList')}
          className={editorClasses.toolbar.bulletList}
          onClick={() =>
            editor
              .chain()
              .focus()
              .toggleBulletList()
              .run()
          }
          icon={<UnorderedListOutlined />}
        />
        <ToolbarItem
          aria-label="Ordered list"
          active={editor.isActive('orderedList')}
          className={editorClasses.toolbar.orderedList}
          onClick={() =>
            editor
              .chain()
              .focus()
              .toggleOrderedList()
              .run()
          }
          icon={<OrderedListOutlined />}
        />
      </Space>

      <Space size="small">
        <ToolbarItem
          aria-label="Align left"
          active={editor.isActive({ textAlign: 'left' })}
          className={editorClasses.toolbar.alignLeft}
          onClick={() =>
            editor
              .chain()
              .focus()
              .setTextAlign('left')
              .run()
          }
          icon={<AlignLeftOutlined />}
        />
        <ToolbarItem
          aria-label="Align center"
          active={editor.isActive({ textAlign: 'center' })}
          className={editorClasses.toolbar.alignCenter}
          onClick={() =>
            editor
              .chain()
              .focus()
              .setTextAlign('center')
              .run()
          }
          icon={<AlignCenterOutlined />}
        />
        <ToolbarItem
          aria-label="Align right"
          active={editor.isActive({ textAlign: 'right' })}
          className={editorClasses.toolbar.alignRight}
          onClick={() =>
            editor
              .chain()
              .focus()
              .setTextAlign('right')
              .run()
          }
          icon={<AlignRightOutlined />}
        />
        <ToolbarItem
          aria-label="Align justify"
          active={editor.isActive({ textAlign: 'justify' })}
          className={editorClasses.toolbar.alignJustify}
          onClick={() =>
            editor
              .chain()
              .focus()
              .setTextAlign('justify')
              .run()
          }
          icon={<BiAlignJustify />}
        />
      </Space>

      <LinkBlock editor={editor} />
    </Space>
  );
}
