import React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { EditorToolbarItemProps } from './EditorTypes';

export function ToolbarItem({
  icon,
  label,
  active,
  disabled,
  ...other
}: ButtonProps & EditorToolbarItemProps) {
  return (
    <Button
      className="toolbar-item-button"
      style={{
        backgroundColor: active ? '#f0f0f0' : undefined,
        cursor: disabled ? 'not-allowed' : 'pointer',
        opacity: disabled ? 0.48 : 1,
        pointerEvents: disabled ? 'none' : undefined
      }}
      //   @ts-ignore
      icon={icon && React.cloneElement(icon, { style: { fontSize: 18 } })}
      disabled={disabled}
      {...other}
    >
      {label && label}
    </Button>
  );
}
