import React from 'react';
import LinkExtension from '@tiptap/extension-link';
import StarterKitExtension from '@tiptap/starter-kit';
import TextAlignExtension from '@tiptap/extension-text-align';
import PlaceholderExtension from '@tiptap/extension-placeholder';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import { useEffect, forwardRef } from 'react';
import { Color } from '@tiptap/extension-color';
import {
  useEditor,
  EditorContent,
  EditorOptions
} from '@tiptap/react';
import { Button, Space } from 'antd';
import { editorClasses } from './helpers/EditorHelpers';
import { Toolbar } from './helpers/EditorToolbar';

export type EditorProps = Partial<EditorOptions> & {
  onSubmit?: (contentHtml: string) => Promise<void> | void;
  initialHtml?: string;
  value?: string;
  error?: boolean;
  fullItem?: boolean;
  resetValue?: boolean;
  placeholder?: string;
  helperText?: React.ReactNode;
  onChange?: (value: string) => void;
  isSubmitting?: boolean;
  buttonTitle?: string;
  editorRootClassName?: string;
};

export const Editor = forwardRef<HTMLDivElement, EditorProps>(
  (
    {
      error,
      onChange,
      helperText,
      resetValue,
      editable = true,
      fullItem = false,
      value: content = ``,
      placeholder = 'Write something awesome...',
      onSubmit,
      initialHtml = '',
      isSubmitting,
      buttonTitle,
      editorRootClassName,
      ...other
    },
    ref
  ) => {
    const editor = useEditor({
      content: initialHtml || content,
      editable,
      extensions: [
        StarterKitExtension.configure({
          codeBlock: false,
          heading: { HTMLAttributes: { class: editorClasses.content.heading } },
          horizontalRule: { HTMLAttributes: { class: editorClasses.content.hr } },
          listItem: { HTMLAttributes: { class: editorClasses.content.listItem } },
          blockquote: { HTMLAttributes: { class: editorClasses.content.blockquote } },
          bulletList: { HTMLAttributes: { class: editorClasses.content.bulletList } },
          orderedList: { HTMLAttributes: { class: editorClasses.content.orderedList } }
        }),
        PlaceholderExtension.configure({
          placeholder,
          emptyEditorClass: editorClasses.content.placeholder
        }),
        Underline.configure({ HTMLAttributes: { class: editorClasses.content.underline } }),
        TextAlignExtension.configure({ types: ['heading', 'paragraph'] }),
        LinkExtension.configure({
          autolink: true,
          openOnClick: false,
          HTMLAttributes: { class: editorClasses.content.link }
        }),
        Color,
        TextStyle
      ],
      onUpdate({ editor: _editor }) {
        const html = _editor.getHTML();
        onChange?.(html);
      },
      ...other
    });

    const currentEditorHtml = editor?.getHTML() || '<p></p>';

    const resetEditorState = () => {
      editor?.commands.setContent(initialHtml);
    };

    useEffect(() => {
      const timer = setTimeout(() => {
        if (editor?.isEmpty && content !== '<p></p>') {
          editor.commands.setContent(initialHtml || content);
        }
      }, 100);
      return () => clearTimeout(timer);
    }, [content, editor]);

    useEffect(() => {
      if (resetValue && !content) {
        editor?.commands.clearContent();
      }
    }, [content]);

    return (
      <>

        <Space style={{ ...(!editable && { cursor: 'not-allowed' }) , marginBottom:"10px"}}>
          <div
            className={`${editorClasses.root} ${editorRootClassName || ''}`}
          >
            <Toolbar
              editor={editor}
              fullItem={fullItem}
            />
            <EditorContent
              ref={ref}
              spellCheck="false"
              autoComplete="off"
              autoCapitalize="off"
              editor={editor}
              className={editorClasses.content.root}
            />
          </div>

          {helperText && (
            <p style={{ paddingBlock: 2, color: !!error ? 'red' : 'default' }}>{helperText}</p>
          )}
        </Space>
        <Button
          type='primary'
          loading={isSubmitting}
          onClick={async () => {
            onSubmit && (await onSubmit(currentEditorHtml));
            resetEditorState();
          }}
        >
          {buttonTitle || 'Notiz hinzufügen'}
        </Button>
      </>
    );
  }
);
