import gql from 'graphql-tag';

export const SET_BOOKING_NOTE = gql`
  mutation SetBookingNote($input: BookingNoteData!) {
    setBookingNote(input: $input) {
      id
      coachId
      bookingId
      note
      createdAt
    }
  }
`;
