import React, { useState } from 'react';
import { Modal, Tabs } from 'antd';
import { BiImages, BiImage } from 'react-icons/bi';

import { ImageType } from '../utils/LibraryModels';
import PexelesImagePicker from '../../GeneralComponents/ExternalImagePicker/container/PexelesImagePicker';
import LibraryImagePicker from './LibraryImagePicker';

function ImageSelectModal({
  trigger,
  handleLibraryImage,
  handleStockImage,
  stockImageQuality,
  classes,
  fileList,
  handleChange
}: {
  trigger: JSX.Element;
  handleLibraryImage: (image: ImageType) => void;
  handleStockImage: (img: string) => void;
  handleChange: ({ fileList }: any) => Promise<false | undefined>;
  stockImageQuality?: number;
  classes?: string;
  fileList?: any[];
}) {
  const [visible, setVisibility] = useState(false);

  const handleCancel: () => void = () => {
    setVisibility(false);
  };

  return (
    <React.Fragment>
      <div onClick={() => setVisibility(true)} className={classes}>
        {trigger}
      </div>
      <Modal
        title={'Archiv / Stockfotos'}
        width={'60%'}
        bodyStyle={{ overflow: 'hidden', maxHeight: '90vh' }}
        visible={visible}
        destroyOnClose
        className="mb-6 builder__settings__image-select-modal"
        onCancel={handleCancel}
        footer={null}
      >
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane
            tab={
              <span className="d-flex align-items-center">
                Archiv
                <BiImages className="ms-1" />
              </span>
            }
            key="1"
          >
            <LibraryImagePicker
              fileList={fileList}
              handleChange={handleChange}
              onSelectImage={data => {
                handleLibraryImage(data);
                handleCancel();
              }}
            />
          </Tabs.TabPane>

          <Tabs.TabPane
            tab={
              <span className="d-flex align-items-center">
                Stockfotos
                <BiImage className="ms-1" />
              </span>
            }
            key="2"
          >
            <PexelesImagePicker
              quality={stockImageQuality}
              onChange={url => {
                // @ts-ignore
                handleStockImage(url.full);
                handleCancel();
              }}
              height={'60vh'}
            />
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </React.Fragment>
  );
}

export default ImageSelectModal;
