import React, { useState } from 'react';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { Button, message, Popover } from 'antd';
import DeleteButtonWithConfirmation from '../../../SharedUI/components/DeleteButtonWithConfirmation';
import { IoEllipsisHorizontalCircle } from 'react-icons/io5';
import { BiEdit } from 'react-icons/bi';
import { Editor } from '../../../SharedUI/components/Editor';
import { BookingNoteType } from '../../utils/responseTypes';
import { GeneralMessages } from '../../../config/messages';

interface SingleNoteProps {
  noteObject: BookingNoteType;
  removeNoteLoading: boolean;
  setNoteToBeDeleted: (coachId: number) => void;
  handleDeleteNote: (id: number) => void;
  handleAddNote: (note: string, id?: number) => Promise<void>;
}

const SingleNote = ({
  noteObject,
  removeNoteLoading,
  handleDeleteNote,
  setNoteToBeDeleted,
  handleAddNote
}: SingleNoteProps) => {
  const { id } = useCurrentUser();
  const isOwner = noteObject.coachId === id;
  const [isEditing, setIsEditing] = useState(false);
  const [currentNote, setCurrentNote] = useState(noteObject.note);
  const toggleIsEditing = () => {
    setIsEditing(!isEditing);
  };
  const handleSubmit = async (e: string) => {
    try {
      toggleIsEditing();
      await handleAddNote(e, noteObject.id);
      setCurrentNote(e);
    } catch (error) {
      message.warn(GeneralMessages.error);
    }
  };
  return (
    <>
      {isEditing ? (
        <Editor initialHtml={currentNote} onSubmit={handleSubmit} buttonTitle="Update-Hinweis" />
      ) : (
        <div className="booking-note">
          <div
            dangerouslySetInnerHTML={{
              __html: currentNote?.startsWith('<') ? currentNote : `<p>${currentNote}</p>`
            }}
          ></div>
          {isOwner && (
            <div className="delete-note">
              <Popover
                placement="leftBottom"
                content={
                  <>
                    <DeleteButtonWithConfirmation
                      loading={removeNoteLoading}
                      onDelete={async () => {
                        setNoteToBeDeleted(noteObject.coachId);
                        handleDeleteNote(noteObject.id);
                      }}
                    />
                    <Button
                      onClick={() => toggleIsEditing()}
                      type="link"
                      className="w-100"
                      style={{ borderTop: '0.5px solid' }}
                    >
                      <BiEdit fontSize={15}/>
                      Bearbeiten
                    </Button>
                  </>
                }
              >
                <Button
                  className="ant-btn__without-background"
                  type="ghost"
                  data-testid="booking-delete-button"
                  disabled={removeNoteLoading}
                >
                  <IoEllipsisHorizontalCircle />
                </Button>
              </Popover>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SingleNote;
