import { Button, Checkbox, Modal, Result } from 'antd';
import React from 'react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

const WarningModalWithHideForeverCheckbox = ({
  onCancel,
  visible,
  setVisible,
  onSubmit,
  checkboxVisible,
  localStorageKey,
  title,
  content,
  cancelButtonText,
  submitButtonText,
  iconPath,
  showCancelButton = true,
  IconComponent = null
}: {
  visible: boolean;
  setVisible: (e: boolean) => void;
  onSubmit: () => void;
  onCancel?: () => void;
  checkboxVisible?: boolean;
  localStorageKey: string;
  title: string;
  content: JSX.Element;
  cancelButtonText?: string;
  submitButtonText?: string;
  iconPath?: string;
  showCancelButton?: boolean;
  IconComponent?: React.ReactNode;
}) => {
  const toggleModal = () => setVisible(false);
  const currentShowCount = parseInt(
    localStorage.getItem('meetovoContainerCreateWarningShowCount' || '') || '0'
  );

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked) localStorage.setItem(localStorageKey, 'true');
    else localStorage.removeItem(localStorageKey);
  };

  const buttons = [
    <Button
      role="button"
      type="primary"
      key="WarningForWithoutContainerElement__1"
      onClick={() => {
        onSubmit();
        toggleModal();
      }}
    >
      {submitButtonText || 'Ja'}
    </Button>
  ];
  if (showCancelButton) {
    buttons.push(
      <Button
        role="button"
        key="WarningForWithoutContainerElement__2"
        type="link"
        onClick={() => {
          onCancel && onCancel();
          toggleModal();
        }}
      >
        {cancelButtonText || 'Nein'}
      </Button>
    );
  }

  return (
    <Modal visible={visible} onOk={onSubmit} onCancel={toggleModal} footer={null}>
      <Result
        icon={iconPath ? <img width={70} src={iconPath} /> : IconComponent ? IconComponent : null}
        status="info"
        title={title}
        subTitle={
          <>
            {content}
            {(currentShowCount >= 1 || checkboxVisible) && (
              <Checkbox onChange={handleCheckboxChange}>Hinweis nicht erneut anzeigen</Checkbox>
            )}
          </>
        }
        extra={buttons}
      />
    </Modal>
  );
};

export default WarningModalWithHideForeverCheckbox;
