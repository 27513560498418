import React, { useEffect, useState } from 'react';
import { Row, Col, Slider } from 'antd';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';

import { CropperShape } from '../../../../../GeneralComponents/ImageUploadV3';
import { commonAspectRatios } from '../../../../../GeneralComponents/helper/cropImage';
import { useEditor } from '@craftjs/core';
import BuilderSettingsTitle from '../../../sharedUI/BuilderSettingsTitle';
import { defaultImageOpacity } from '../../../../AdBuilder/interfaces/ImageSizeInterface';
import ActionProviderHOCControllable from '../../../sharedUI/ActionProviderHOCControllable';
import { MdDragHandle } from 'react-icons/md';
import EditableLabel from './EditableLabel';
import { handleBringSlideToFront } from '../../../../helper/craftJs';
import ImageUploadV3 from '../../../../../GeneralComponents/ImageUploadV3';

const DragHandle = SortableHandle(() => <MdDragHandle className="drag-handler" />);

const marks = {
  1: '10%',
  2: '20%',
  3: '30%',
  4: '40%',
  5: '50%',
  6: '60%',
  7: '70%',
  8: '80%'
};
const CarouselImageUpload = SortableElement(({ handleDelete, idx, id, deletable }: any) => {
  const { query, actions } = useEditor();

  const [imageSrc, setImageSrc] = useState(query.getNodes()[id]?.data?.props?.image);
  const [label, setLabel] = useState(query.getNodes()?.[id]?.data?.props.label || '');
  const [opacity, setOpacity] = useState(query.getNodes()[id]?.data?.props?.opacity);

  useEffect(() => {
    if (!query.node(id).get()) return;
    setImageSrc(query.getNodes()?.[id]?.data?.props.image);
    setOpacity(query.getNodes()?.[id]?.data?.props.opacity);
    setLabel(query.getNodes()?.[id]?.data?.props.label || '');
  }, [id]);

  const handleFileUploaded = (image: string) => {
    setImageSrc(image);
    actions.setProp(id, state => {
      state.image = image;
    });
  };

  const handleLabelChange = (val: string) => {
    setLabel(val);
    actions.history.throttle().setProp(id, state => {
      state.label = val;
    });
  };

  const handleOpacityChanged = (value: any) => {
    setOpacity(value);
    actions.setProp(id, state => {
      state.opacity = value;
    });
  };

  const handleFocus = () => {
    if (!query.node(id).get()) return;
    const parent = query.getNodes()?.[id].data.parent;
    handleBringSlideToFront(parent, id);
  };

  return (
    <div
      className="mb-3"
      style={{
        zIndex: 9,
        background: '#fff'
      }}
      onClick={handleFocus}
    >
      <ActionProviderHOCControllable
        deletable={deletable}
        title={
          <div className="d-flex align-items-center">
            <DragHandle />
            <EditableLabel className="mx-2" value={label} setValue={handleLabelChange} />
          </div>
        }
        onDelete={() => {
          handleDelete(idx);
        }}
      >
        <Row className="builder__settings-sidebar__row m-2">
          <Col span={24}>
            <ImageUploadV3
              previewImage={imageSrc}
              onChange={e => {
                handleFileUploaded(e);
              }}
              minWidth={commonAspectRatios[1].width}
              minHeight={commonAspectRatios[1].height}
              restrictPosition={true}
              minZoom={0}
              uploadedImage
              freeSelectionEnabled={false}
              shape={CropperShape.RECTANGLE}
              circleEnabled={false}
              possibleAspectRatios={[commonAspectRatios[1]]}
              cropperIsNotMandatoryAfterSelectionFromLibrary={false}
            />
          </Col>
          <Col span={24}>
            <BuilderSettingsTitle title="Overlay-Transparenz" />
          </Col>
          <Col span={24} className="px-2">
            <Slider
              className="builder-slider-style"
              marks={marks}
              min={1}
              max={8}
              tooltipVisible={false}
              defaultValue={defaultImageOpacity.key}
              value={opacity}
              onChange={value => {
                handleOpacityChanged(value);
              }}
            />
          </Col>
        </Row>
      </ActionProviderHOCControllable>
    </div>
  );
});

export default CarouselImageUpload;
