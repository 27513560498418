import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllFunnels } from '../../Builder/hooks/useGetAllFunnels';
import { getAllFunnelFolders } from '../../Builder/hooks/useGetAllFunnelFolders';
import updateFunnelFolder from '../../Builder/graphql/updateFunnelFolder';
import createFunnelFolder from '../../Builder/graphql/createFunnelFolder';
import setFunnelFolder from '../../Builder/graphql/setFunnelFolder';
import deleteFunnelFolder from '../../Builder/graphql/deleteFunnelFolder';
import { getFunnelsBookingCount } from '../../Builder/hooks/useGetFunnelBookingsCount';
import setWidgetHub from '../../Builder/WidgetsBuilder/graphql/setWidgetHub';
import { getAllWidgets } from '../../Builder/WidgetsBuilder/graphql/widgetGqlQueries';

export const getAllFunnelsThunk = createAsyncThunk('get-all-funnels', async (onlyDeletedFunnels: boolean) => {
  try {
    const { data: funnelsData } = await getAllFunnels(onlyDeletedFunnels);
    return funnelsData?.getAllFunnels;
  } catch (error) {
    throw error;
  }
});

export const getAllWidgetsThunk = createAsyncThunk('get-all-widgets', async (onlyDeletedWidgets: boolean) => {
  try {
    const { data: widgetsData } = await getAllWidgets(onlyDeletedWidgets);
    return widgetsData.getAllWidgets;
  } catch (error) {
    throw error;
  }
});

export const getAllFunnelFoldersThunk = createAsyncThunk('get-all-funnel-folders', async () => {
  try {
    const { data: funnelFoldersData } = await getAllFunnelFolders();
    return funnelFoldersData?.getFunnelFolders;
  } catch (error) {
    throw error;
  }
});

export const addFunnelFolderThunk = createAsyncThunk(
  'add-funnel-folder',
  async (data: { name: string }) => {
    try {
      const createFolder = await createFunnelFolder(data);
      return createFolder.data?.createFunnelFolder;
    } catch (error) {
      throw error;
    }
  }
);

export const updateFunnelFolderThunk = createAsyncThunk(
  'update-funnel-folder',
  async (data: { id: number; name: string }) => {
    try {
      const updateFolder = await updateFunnelFolder(data);
      return updateFolder.data?.updateFunnelFolder;
    } catch (error) {
      throw error;
    }
  }
);

export const moveFunnelInsideFolderThunk = createAsyncThunk(
  'move-funnel-inside-folder',
  async (data: { id: number; funnelFolderId: number }) => {
    try {
      await setFunnelFolder(data);
      return { folderId: data.funnelFolderId, movingFunnelId: data.id };
    } catch (error) {
      throw error;
    }
  }
);

export const moveWidgetInsideFolderThunk = createAsyncThunk(
  'move-widget-inside-folder',
  async (data: { id: number; widgetFolderId: number }) => {
    try {
      await setWidgetHub({ id: data.id, folderId: data.widgetFolderId});
      return { widgetId: data.widgetFolderId, movingWidgetId: data.id };
    } catch (error) {
      throw error;
    }
  }
);

export const removeFunnelFromFolderThunk = createAsyncThunk(
  'remove-funnel-from-folder',
  async (data: { id: number }) => {
    try {
      await setFunnelFolder({ id: data.id, funnelFolderId: null });
      return { funnelId: data.id };
    } catch (error) {
      throw error;
    }
  }
);

export const removeWidgetFromFolderThunk = createAsyncThunk(
  'remove-widget-from-folder',
  async (data: { id: number }) => {
    try {
      await setWidgetHub({ id: data.id, folderId: null });
      return { widgetId: data.id };
    } catch (error) {
      throw error;
    }
  }
);

export const deleteFunnelFolderThunk = createAsyncThunk(
  'delete-funnel-folder',
  async (data: { id: number }) => {
    try {
      await deleteFunnelFolder(data);
      return { selectedFolderId: data.id };
    } catch (error) {
      throw error;
    }
  }
);

export const getFunnelsBookingCountThunk = createAsyncThunk(
  'get-funnel-booking-count',
  async (funnelIds: number[]) => {
    try {
      const { data } = await getFunnelsBookingCount(funnelIds);

      const hashMap: { [funnelId: number]: number } = {};
      funnelIds.forEach(element => {
        hashMap[element] = 0;
      });

      data.getBookingCountByFunnelsId.forEach(
        (element: { bookingCount: number; funnelId: number }) => {
          hashMap[element.funnelId] = element.bookingCount;
        }
      );

      return hashMap;
    } catch (error) {
      throw error;
    }
  }
);
