import React from 'react';
import { Avatar as AntAvatar, Divider, Popover } from 'antd';
import { PoweroffOutlined, UserOutlined } from '@ant-design/icons';

import { useCurrentUser } from '../../hooks/useCurrentUser';
import Avatar from 'react-avatar';
import { Role } from '../utils/roleTypes';
import { useNavigate } from 'react-router';
interface UserAvatar {
  shouldShowMyAccount?: boolean;
}

const UserAvatar = (props: UserAvatar) => {
  const navigate = useNavigate();
  const userData = useCurrentUser();
  if (!userData) return null;

  const { shouldShowMyAccount } = props;
  const { imageURL, firstName, lastName, email } = userData;
  const isProfileImagePlaceholder = imageURL.includes('placeholder');

  return (
    <Popover
      placement="bottomRight"
      title={
        <div className="profile-popover__header flex-wrapper-two vertical-centered">
          {isProfileImagePlaceholder ? (
            <Avatar name={`${firstName} ${lastName}`} size="60" textSizeRatio={2} round={true} />
          ) : (
            <AntAvatar
              size={60}
              src={imageURL || ''}
              icon={!imageURL ? <UserOutlined /> : false}
              style={{ verticalAlign: 'middle' }}
            />
          )}
          <div className="flex-vertical-center">
            <label>
              {firstName} {lastName}
            </label>
            <p>{email}</p>
          </div>
        </div>
      }
      content={
        <React.Fragment>
          {shouldShowMyAccount ? (
            <div className="profile-popover__item" onClick={() => navigate('/account')}>
              <UserOutlined />
              Mein Account
            </div>
          ) : null}

          <Divider style={{ margin: 0 }} />
          <div
            className="profile-popover__item profile-popover__logout"
            onClick={() => {
              window.handleLogout();
            }}
          >
            <PoweroffOutlined />
            Logout
          </div>
        </React.Fragment>
      }
      trigger="click"
    >
      {isProfileImagePlaceholder ? (
        <Avatar name={`${firstName} ${lastName}`} size="40" textSizeRatio={2} round={true} />
      ) : (
        <AntAvatar
          src={imageURL}
          icon={!imageURL ? <UserOutlined /> : false}
          style={{ verticalAlign: 'middle', cursor: 'pointer' }}
          size="large"
        />
      )}
    </Popover>
  );
};

export default UserAvatar;
