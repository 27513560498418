export enum LocalStorageKeys {
  MEETOVO_FORCE_CONNECT_DEV = 'MEETOVO_FORCE_CONNECT_DEV',
  MEETOVO_INACTIVE_MODAL_VISIBLE = 'MEETOVO_INACTIVE_MODAL_VISIBLE',
  MEETOVO_AUTH_DATA = 'meetovoAuthData',
  CURRENTLY_OPENED_FUNNELS = 'CURRENTLY_OPENED_FUNNELS',
  SIDEBAR_VIEW = 'SIDEBAR_VIEW',
  DELETE_FUNNEL_WARNING = 'DELETE_FUNNEL_WARNING',
  DELETE_WIDGET_WARNING = 'DELETE_WIDGET_WARNING',
  MEETOVO_CHECKOUT_PACKAGE_DETAILS = 'meetovoCheckoutPackageDetails',
  MEETOVO_CHECKOUT_USER_INFO = 'meetovoCheckoutUserInfo',
  MEETOVO_FUNNEL_OWNER = 'meetovoFunnelOwner'
}
